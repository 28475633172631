import { createContext, useCallback, useContext, useState } from "react";
import { setCookie, destroyCookie } from 'nookies'
import { useNavigate } from "react-router-dom";
import { UserModel } from "../services/models/user.model";
import authService from "../services/http/auth.service";
import Utils from "../services/utils";

interface AuthContextProps {
  user: UserModel;
  signIn: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  loading: boolean;
  getLoggedUser: () => UserModel | null;
}
const AuthContext = createContext({} as AuthContextProps)

export function AuthProvider({ children }: any) {
  const navigate = useNavigate()
  const [user, setUser] = useState<UserModel | null | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const getLoggedUser = () => {
    // Get user from cookie
    const userToken = Utils.getCookie('sensar@user');
    if (userToken == null) {
      setUser(null);
      setLoading(false);
      return null;
    }

    const user = JSON.parse(userToken);
    setUser({
      id: user.id,
      name: user.name,
      email: user.email,
    })

    setLoading(false);

    return user;
  };

  const signIn = useCallback(async (email: string, password: string) => {
    try {
      setLoading(true);
      const response = authService.signIn(email, password);
      const responseData = (await response).data;
      console.log('Login response: ', responseData);

      setCookie(undefined, 'sensar@token', responseData.accessToken, {
        maxAge: 60 * 60 * 24 * 30, // 30 days,
        path: '/'
      })

      setCookie(undefined, 'sensar@user', JSON.stringify(responseData.user), {
        maxAge: 60 * 60 * 24 * 30, // 30 days,
        path: '/'
      })

      setUser({
        id: responseData.user.id,
        name: responseData.user.name,
        email: responseData.user.email,
      })

      setLoading(false);
      return true;

    } catch (error) {
      setLoading(false);
      return false;
    }


  }, [])

  const logout = useCallback(() => {
    destroyCookie(undefined, 'sensar@token');
    destroyCookie(undefined, 'sensar@user');
    setUser(null);
    navigate('/login');
  }, [])

  const props = {
    user, signIn, logout, loading, getLoggedUser
  } as AuthContextProps;

  return (
    <AuthContext.Provider value={props}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext) as AuthContextProps;

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
