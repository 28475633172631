import axios from "axios";
import { ListLimitsResponseDto } from "../responses/list-limit-response.dto";
import { CreateSensorLimitDTO } from "../dto/create-sensor-limit.dto";
import { ListOutlierConfigResponseDto } from "../responses/list-outlier-config-response.dto";
import { CreateOutlierConfigDto } from "../dto/create-outlier-config.dto";

class OutlierDetectionService {
  private readonly baseUrl = process.env.REACT_APP_REST_API_ENDPOINT + '/outlier-detection';

  public listSensorOutlierConfig(sensorId: string) {
    return axios.get<ListOutlierConfigResponseDto[]>(this.baseUrl + `/${sensorId}`);
  }

  public saveOutlierConfig(dto: CreateOutlierConfigDto) {
    return axios.post<CreateOutlierConfigDto>(this.baseUrl, dto);
  }

}
const outlierDetectionService = new OutlierDetectionService();
export default outlierDetectionService;