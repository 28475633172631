import styled from "styled-components";

export const ThemeIconOnlyButton = styled.button`
  cursor: pointer;
  color: ${props => props.theme.button.color};
  font-size:${props => props.theme.button.iconOnlyButton.fontSize};
`;

export const ThemeIconOnlyOutlinedButton = styled.button`
  cursor: pointer;
  color: ${props => props.theme.button.color};

  border:  ${props => props.theme.button.outlinedButton.border};
  border-radius: ${props => props.theme.button.borderRadius};

  padding: ${props => props.theme.button.padding};
  height: ${props => props.theme.button.outlinedButton.iconOnlyButtonOutlined.size};
  width: ${props => props.theme.button.outlinedButton.iconOnlyButtonOutlined.size};

  &:hover{
		border-color: ${props => props.theme.button.outlinedButton.borderColorHovered};
	}

  &:focus{
		border-color: ${props => props.theme.button.outlinedButton.borderColorFocused};
	}

  &:active{
    border-color: ${props => props.theme.button.outlinedButton.borderColorFocused};
  }

`;

export const ThemeIconOnlyFilledButton = styled.button`
  cursor: pointer;
  color: ${props => props.theme.button.color};
  background-color: ${props => props.theme.button.backgroundColor};
  font-size: ${props => props.theme.button.iconOnlyFilledButton.fontSize};

  border:  ${props => props.theme.button.border};
  border-radius: ${props => props.theme.button.borderRadius};

  padding: ${props => props.theme.button.padding};

  &:hover{
		border-color: ${props => props.theme.button.borderColorHovered};
  background-color: ${props => props.theme.button.backgroundColorHovered};

	}

  &:focus{
		border-color: ${props => props.theme.button.borderColorFocused};
    background-color: ${props => props.theme.button.backgroundColorFocused};
	}

  &:active{
    border-color: ${props => props.theme.button.borderColorFocused};
    background-color: ${props => props.theme.button.backgroundColorActive};
  }

`;