import { LogoImg } from './Logo.styles'
interface ILogoProps {
	size?: number;
}
export function Logo({ size = 128 }: ILogoProps) {
	return (
		<LogoImg src='/icone-light.svg' alt='Logo sensar' $width={size}></LogoImg>
	)
}


