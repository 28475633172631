import { Popover } from "react-tiny-popover";
import { BasePopupContainer } from "../../../../components/Popover/ThemePopover.styles";
import { ThemeIconOnlyFilledButton } from "../../../../components/Buttons/IconOnly/ThemeIconOnly.styles";
import { TbChartSankey } from "react-icons/tb";
import { Flex } from "../../../../components/Flex/Flex.styles";
import { ThemeInputNumber } from "../../../../components/Form/Input/Input.styles";
import { ThemeFilledButton, ThemeFilledSecondaryButton, ThemeSelectButton } from "../../../../components/Buttons/Primary/Primary.styles";
import { UseFormRegister } from "react-hook-form";
import { PopoverTitle } from "./ChartLimit.styles";
import { ChartLimitFooter } from "../ChartArea.styles";

export interface LimitFormData {
  upperLimit: number | null;
  lowerLimit: number | null;
  limitAxis?: number;
}
interface IChartLimitsProps {
  isOpen: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
  selectedAxis?: number;
  register: UseFormRegister<LimitFormData>;
  onAxisChange?: (newAxis: number) => void;
  onLimitSave: () => void;
  isMultiLimit: boolean;
}
export function ChartLimit(props: IChartLimitsProps) {

  return (
    <Popover isOpen={props.isOpen} positions={['left']}
      padding={10}
      // onClickOutside={(e) => {
      //   console.log("click outside", e);
        
      //   props.onCloseModal()
      // }}
      content={
        <BasePopupContainer>
          <form>
            <Flex className="column">

              <Flex className="justify-content-center" style={{ paddingBottom: '1rem' }}>
                <PopoverTitle>Limites críticos</PopoverTitle>
              </Flex>

              {props.isMultiLimit &&
                <Flex className="justify-content-center" $columnGap="1rem" $padding="0px 0px 1rem 0px">
                  <ThemeSelectButton type="button" $selected={props.selectedAxis === 0} $width="3rem"
                    onClick={() => props.onAxisChange?.(0)}>X</ThemeSelectButton>
                  <ThemeSelectButton type="button" $selected={props.selectedAxis === 1} $width="3rem"
                    onClick={() => props.onAxisChange?.(1)}>Y</ThemeSelectButton>
                  <ThemeSelectButton type="button" $selected={props.selectedAxis === 2} $width="3rem"
                    onClick={() => props.onAxisChange?.(2)}>Z</ThemeSelectButton>
                </Flex>
              }

              <div style={{ paddingBottom: "0.5rem" }}>
                <div>Valor máximo</div>
                <ThemeInputNumber {...props.register("upperLimit", { valueAsNumber: true })}></ThemeInputNumber>
              </div>

              <div style={{ paddingBottom: "1rem" }}>
                <div>Valor mínimo</div>
                <ThemeInputNumber {...props.register("lowerLimit", { valueAsNumber: true })}></ThemeInputNumber>
              </div>

              <ChartLimitFooter>
                <ThemeFilledSecondaryButton type="button" onClick={() => props.onCloseModal()}>Cancelar</ThemeFilledSecondaryButton>
                <ThemeFilledButton type="button" onClick={props.onLimitSave}>Salvar</ThemeFilledButton>
              </ChartLimitFooter>
            </Flex>
          </form>
        </BasePopupContainer>
      }>
      <div>
        <ThemeIconOnlyFilledButton as={TbChartSankey} onClick={() => {
          props.isOpen ? props.onCloseModal() : props.onOpenModal();
        }}></ThemeIconOnlyFilledButton>
      </div>
    </Popover>
  );

}
