import { isSameMonth, isSameYear } from "date-fns";
import { parseCookies } from "nookies";

export const EMAIL_PATTERN = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export default class Utils {
  /** Return last element of a list. Null if list is empty. */
  static Last<T>(list: T[]): T | null {
    if (list.length === 0) {
      return null;
    }

    return list[list.length - 1];
  }

  static Unique(list: any[]): any[] {
    const uniqueValues = list.filter((element, index) => {
      return list.indexOf(element) === index;
    });
    return uniqueValues;
  }

  static getCookie(key: string) {
    const cookies = parseCookies();
    let cookieData;
    try {
      cookieData = cookies[key];
    } catch (error) { }

    return cookieData;
  }

  static stringIsNullOrEmpty(value: string) {
    if (typeof value !== 'string') {
      return false;
    }
    if (value === '') {
      return false;
    }
    if (value == null) {
      return false;
    }
    return true;
  }

  static getDateFromString(date?: string | Date) {
    if (date === undefined) {
      return undefined;
    }

    return new Date(date);
  }

  static capitalizeFirstLetter(str: string): string {
    if (typeof str !== 'string' || str.length === 0) {
      return str;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static listIsEmpty(list: any[]) {
    if (list === undefined || list === null || list.length === 0) {
      return true;
    }
    return false;
  }

  static dateListHasMonth(dateList: Date[], newMonth: Date) {
    if (this.listIsEmpty(dateList) || newMonth == null) {
      return false;
    }

    return dateList.some(month => isSameMonth(month, newMonth) && isSameYear(month, newMonth));
  }
}