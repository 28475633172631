import { useMemo, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { format } from "date-fns";
import { ChartTitle, ChartButton, ChartContainer } from "./SimpleChart.styles";
import { SingleAxisResponse } from "../../../services/responses/sensor-data-response.dto";
import { Flex } from "../../Flex/Flex.styles";
import { FaSearchMinus } from "react-icons/fa";
import { ChartLimit, LimitFormData } from "../../../pages/Dashboard/ChartArea/ChartLimit/ChartLimit";
import { UseFormRegister } from "react-hook-form";
import { useTheme } from "styled-components";
import { getSingleAxisDataset } from "../Chart";
import Loading from "../../Loading/Loading";
import { Center } from "../../Center/Center.styles";

interface IChartLimit {
  modalProps: {
    isOpen: boolean;
    onOpenModal: () => void;
    onCloseModal: () => void;
  }
  showLimitForm: boolean;
  register: UseFormRegister<LimitFormData>;
  onLimitSave: () => void;
  upperLimit: number | null;
  lowerLimit: number | null;
}
interface ISimpleChart extends IChartLimit {
  data: SingleAxisResponse[];
  title: string;
  label: string;
  isLoading?: boolean;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export function SimpleChart(props: ISimpleChart) {
  const { data, label, title, isLoading = false, upperLimit, lowerLimit } = props;

  const theme = useTheme();

  const chartRef = useRef<any | null>(null);

  const [drag, setDrag] = useState(false);
  const toggleDrag = () => {
    setDrag(!drag);
    chartRef?.current?.resetZoom();
  };

  const chartData: any = {
    labels: data?.map((item) => {
      return format(new Date(item.createdAt), "dd/MM HH:mm");
    }),
    datasets: useMemo(() => {
      if (data === undefined) return [];
      const points = data?.map((item) => {
        return item.value;
      }) ?? [];
      const newDatasets: any[] = [getSingleAxisDataset(points, label, theme.chart.singleAxisLineColor as string)];
      if (upperLimit != null && !isNaN(upperLimit)) {
        const points = data?.map(() => {
          return upperLimit;
        }) ?? [];
        newDatasets.push(getSingleAxisDataset(points, 'Limite superior', theme.chart.limitLineColor as string));
      }
      if (lowerLimit != null && !isNaN(lowerLimit)) {
        const points = data?.map(() => {
          return lowerLimit;
        }) ?? [];
        newDatasets.push(getSingleAxisDataset(points, 'Limite inferior', theme.chart.limitLineColor as string));
      }
      return newDatasets;
    }, [data, upperLimit, lowerLimit]),
  };
  const chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.3,
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: false,
          mode: "x",
        },
        zoom: {
          mode: "x",
          wheel: { enabled: false },
          drag: { enabled: true },
        },
      },
      tooltip: {
        filter: (context: any) => {
          return context.datasetIndex === 0;
        }
      },
      legend: {
        labels: {
          color: theme.chart.tickColor,
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: theme.chart.tickColor
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: theme.chart.tickColor
        },
        grid: {
          color: theme.chart.gridColor,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  };

  if (isLoading) {
    return (
      <ChartContainer style={{ height: "300px" }}>
        <Center>
          <Loading />
        </Center>
      </ChartContainer>
    );
  }

  return (
    <ChartContainer>

      <Flex className="justify-content-between">
        <ChartTitle>{title}</ChartTitle>

        <Flex className="justify-content-end align-items-center" $columnGap="1rem">
          {props.showLimitForm &&
            <ChartLimit {...props.modalProps} register={props.register} onLimitSave={props.onLimitSave}
              isMultiLimit={false}
            />
          }
          <ChartButton onClick={toggleDrag}>
            <FaSearchMinus fontSize={18}></FaSearchMinus>
          </ChartButton>
        </Flex>
      </Flex>
      <div style={{ height: "400px", width: "100%", position: "relative" }}>
        <Line height={400} ref={chartRef} data={chartData} options={chartOptions} />
      </div>
    </ChartContainer>
  );
}
