import { BsLightningCharge } from "react-icons/bs";
import { SidebarContainer, SidebarNavLink, SidebarTitle, StyledCloseIcon } from "./Sidebar.styles";
import { useNavigate } from "react-router-dom";

interface ISidebarProps {
	isOpen: boolean;
	closeSidebar: () => void;
}
export default function Sidebar(props: ISidebarProps) {
	const navigate = useNavigate();
	const { isOpen, closeSidebar } = props;

	return (
		<SidebarContainer $isOpen={isOpen}>
			<div>
				<SidebarTitle>
					Menu
					<StyledCloseIcon onClick={closeSidebar}></StyledCloseIcon>
				</SidebarTitle>
				<SidebarNavLink onClick={() => { navigate('/home') }}>
					<BsLightningCharge color="var(--red-1)" overflow='visible'></BsLightningCharge>
					Sensores Ativos
				</SidebarNavLink>
			</div>
		</SidebarContainer>
	);
}