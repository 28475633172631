import styled from "styled-components";

export const ThemeInputNumber = styled.input.attrs(props => ({
  type: 'number',
  ...props
})) <{ $width?: string }>`
  width: ${props => props.$width ?? 'auto'};
  color: ${props => props.theme.input.color};
  background-color: ${props => props.theme.input.backgroundColor};
  border: 1px solid ${props => props.theme.input.borderColor};
  font-size: ${props => props.theme.input.fontSize};
  border-radius: ${props => props.theme.input.borderRadius};
  padding:  ${props => props.theme.input.padding};

  &:focus{
		border-color: ${props => props.theme.input.borderColorFocused};
	}

`;
