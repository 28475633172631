import { FlexColumn, Flex, Content } from "./HomeLayout.styles";
import { Header } from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { breakpoint } from "../../styles/theme";

export function HomeLayout(props: { children: JSX.Element }) {
  const { children } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const isMobile = useMediaQuery(`(max-width: ${breakpoint.sm})`)

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  function closeSidebar() {
    setIsSidebarOpen(false);
  }

  return (
    <FlexColumn>
      <Header onSideBarIconClick={toggleSidebar}></Header>
      <Flex>
        <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar}></Sidebar>
        <Content className={isMobile ? 'hide-scrollbar' : ''}>
          {children}
        </Content>
      </Flex>
    </FlexColumn>
  )
}
