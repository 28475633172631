import { useState, useEffect } from "react";
import CanvasJSReact from "../../canvas/canvasjs.react";
import { ChartXAxisRange, setMultiAxisLimitDataset } from "./Chart";
import { useTheme } from "styled-components";
import { Control, useWatch } from "react-hook-form";
import { LimitFormData } from "../../pages/Dashboard/ChartArea/ChartLimit/ChartLimit";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

interface IChartLimit {
  selectedAxis: number;
  limitFormControl: Control<LimitFormData, any>;
}
interface VelocityChartProps extends IChartLimit {
  data: {
    xValue: number[];
    yValue: number[];
    zValue: number[];
  };
  labelX: string;
  labelY: string;
  measurement: string;
}
// Also rename parent folder to Charts
const SAMPLING_FREQUENCY = 6660;

export function VelocityChart({ data, labelX, labelY, measurement, selectedAxis, limitFormControl }: VelocityChartProps) {
  const theme = useTheme();

  const upperLimit = useWatch({control: limitFormControl, name: 'upperLimit'});
  const lowerLimit = useWatch({control: limitFormControl, name: 'lowerLimit'});

  const [upperLimitDatasetOptions, setUpperLimitDatasetOptions] = useState({});
  const [lowerLimitDatasetOptions, setLowerLimitDatasetOptions] = useState({});

  useEffect(() => {
    const xAxisRange: ChartXAxisRange = {
      begin: 0,
      end: (data.xValue.length - 1) / SAMPLING_FREQUENCY,
      axisType: 'number'
    }
    setMultiAxisLimitDataset(xAxisRange, upperLimit, 'upper', selectedAxis, setUpperLimitDatasetOptions);
  }, [upperLimit, selectedAxis]);

  useEffect(() => {
    const xAxisRange: ChartXAxisRange = {
      begin: 0,
      end: (data.xValue.length - 1) / SAMPLING_FREQUENCY,
      axisType: 'number'
    }
    setMultiAxisLimitDataset(xAxisRange, lowerLimit, 'lower', selectedAxis, setLowerLimitDatasetOptions);
  }, [lowerLimit, selectedAxis]);

  return (
    <CanvasJSChart
      options={{
        zoomEnabled: true,
        animationEnabled: true,
        backgroundColor: theme.chart.backgroundColor,
        colorSet: "#FFFFFF",
        theme: "dark2",
        axisX: {
          title: labelX,
          gridThickness: 0,
        },
        axisY: {
          title: labelY,
          gridThickness: 1,
          gridColor: "#4b4d63",
        },
        toolTip: {
          shared: true,
        },
        legend: {
          cursor: "pointer",
          itemclick: function (e: any) {
            if (
              typeof e.dataSeries.visible === "undefined" ||
              e.dataSeries.visible
            ) {
              e.dataSeries.visible = false;
            } else {
              e.dataSeries.visible = true;
            }
            e.chart.render();
          },
        },
        data: [
          {
            type: "line",
            legendText: "Eixo X",
            name: 'Eixo X',
            showInLegend: true,
            yValueFormatString: `0.00 ${measurement}`,
            xValueFormatString: "0.00",
            legendMarkerType: "triangle",
            dataPoints: data?.xValue?.map((velocity, frequency) => {
              return {
                x: frequency / SAMPLING_FREQUENCY,
                y: velocity,
              };
            }),
          },
          {
            type: "line",
            legendText: "Eixo Y",
            name: 'Eixo Y',
            showInLegend: true,
            legendMarkerType: "cross",
            yValueFormatString: `0.00 ${measurement}`,
            xValueFormatString: "0.00",
            dataPoints: data?.yValue?.map((velocity, frequency) => {
              return {
                x: frequency / SAMPLING_FREQUENCY,
                y: velocity,
              };
            }),
          },
          {
            type: "line",
            legendText: "Eixo Z",
            name: 'Eixo Z',
            showInLegend: true,
            yValueFormatString: `0.00 ${measurement}`,
            xValueFormatString: "0.00",
            dataPoints: data?.zValue?.map((velocity, frequency) => {
              return {
                x: frequency / SAMPLING_FREQUENCY,
                y: velocity,
              };
            }),
          },
          upperLimitDatasetOptions,
          lowerLimitDatasetOptions,
        ],
      }}
    />
  );
}
