import CustomDatePicker from "../../../components/DatePicker/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  SizedBox,
  FlexColumn,
  Title,
  FilterContainer,
} from "./Filter.styles";
import { useMemo } from "react";
import TimePicker from "../../../components/TimePicker/TimePicker";
import { isSameDay, startOfDay } from "date-fns";
import { ChartFilter } from "./ChartFilter/ChartFilter";
import texts from "../../../constants/texts";
import { AccelerationDateResponse } from "../../../services/responses/acceleration-date-response.dto";
import { ChartView } from "../Dashboard.types";

interface IFilterProps {
  isLoading: boolean;
  accelerationDates: AccelerationDateResponse[];
  selectedDate?: Date;
  chartViewList: ChartView[];
  onSelectedTimeChange: (accelerationDate: AccelerationDateResponse) => void;
  onFilterMonthChange: (newMonth: Date) => void;
  onSelectedDateChange: (date: Date) => void;
  onTabVisibilityChange: (chartViewList: ChartView[]) => void;
}
export function Filter(props: IFilterProps) {
  const {isLoading, accelerationDates: accelerationDates, selectedDate, chartViewList } = props;

  const {
    pages: {
      dashboard: { filter },
    },
  } = texts;

  const accelerationDays = useMemo(() => {
    const datesMappedToNumbers = accelerationDates.map((x) =>
      startOfDay(x.date).getTime()
    );
    const dateNumberSet = new Set<number>(datesMappedToNumbers);
    const uniqueDates = Array.from(dateNumberSet).map((x) => new Date(x));
    return uniqueDates;
  }, [accelerationDates])
  const accelerationTimes = useMemo(() => {
    if(selectedDate === undefined){
      return [];
    }
    return accelerationDates.filter((x) => isSameDay(selectedDate, x.date));
  }, [accelerationDates, selectedDate])

  return (
    <FlexColumn>
      <Title>{filter.title}</Title>

      <FilterContainer>
        <SizedBox>
          <CustomDatePicker
            availableDates={accelerationDays}
            selectedDate={selectedDate}
            onDateChange={props.onSelectedDateChange}
            onMonthChange={props.onFilterMonthChange}
            isLoadingMonthDates={isLoading}
          ></CustomDatePicker>
        </SizedBox>

        <SizedBox>
          <TimePicker
            accelerationTimes={accelerationTimes}
            selectedDate={selectedDate}
            onTimeChange={props.onSelectedTimeChange}
            isDisabled={isLoading}
          ></TimePicker>
        </SizedBox>

        <SizedBox style={{ flexShrink: 1 }}>
          <ChartFilter
            chartViewList={chartViewList}
            onCheckBoxSelected={props.onTabVisibilityChange}
            isDisabled={isLoading}
          ></ChartFilter>
        </SizedBox>
      </FilterContainer>
    </FlexColumn>
  );
}
