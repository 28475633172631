import axios from "axios";
import { AccelerationDateResponse } from "../responses/acceleration-date-response.dto";

class AccelerationService {
  private readonly baseUrl = process.env.REACT_APP_REST_API_ENDPOINT + '/acceleration';

  /** Get dates that has sensor data */
  public accelerationDates(macAddress: string, month?: number) {
   
    // return axios.get<AccelerationDateResponse[]>(this.baseUrl + `/dates/${macAddress}/${month}`, 
    return axios.get<AccelerationDateResponse[]>(this.baseUrl + `/dates`, 
      {
        params: {
          macAddress,
          month,
        }
      }
    );
  }

}
const accelerationService = new AccelerationService();
export default accelerationService;