import {
  Routes as Switch,
  Route, Navigate
} from "react-router-dom";
import { Home } from "../pages/Home/Home";
import { Login } from "../pages/Login/Login";
import { AuthGuard } from "../components/AuthGuard/AuthGuard";
import { Dashboard } from "../pages/Dashboard/Dashboard";

export function Routes() {

  return (
    <Switch>
      <Route path="/" element={<Navigate to="/home" />}>
      </Route>

      <Route path="/home" element={
        <AuthGuard><Home /></AuthGuard>
      } />

      <Route path="/dashboard/:sensorMacAddress" element={
        <AuthGuard><Dashboard></Dashboard></AuthGuard>
      } />

      <Route path="/login" element={
        <AuthGuard><Login /></AuthGuard>
      } />

      <Route path="*" element={
         <Navigate to="/home" />
      } />

    </Switch>
  )
}