import styled from "styled-components";

export const Flex = styled.div<{ $flex?: string, $padding?: string, $columnGap?: string, $rowGap?: string }>`
	display: flex;
	
	&.column{
		flex-direction: column;
	}

	&.justify-content-center{
		justify-content: center;
	}

	&.justify-content-between{
		justify-content: space-between;
	}

  &.justify-content-end{
		justify-content: flex-end;
	}

	&.align-items-center{
		align-items: center;
	}

  &.ai-end{
		align-items: flex-end;
	}

	&.w-100{
		width: 100%;
	}

	&.h-100{
		height: 100%;
	}

	column-gap:${props => props.$columnGap ?? ''};
	row-gap:${props => props.$rowGap ?? ''};

	padding: ${props => props.$padding ?? ''};

	flex: ${props => props.$flex ?? ''};

`;
