import styled from "styled-components";

export const PopoverTitle = styled.span`
  color: ${props => props.theme.popover.text};
  font-size: ${props => props.theme.popover.titleFontSize};
  font-weight: ${props => props.theme.popover.titleFontWeight};
`

export const ChartAxisOptions = styled.div`
display:grid;
grid-template-columns: 1fr 1fr 1fr;
column-gap: 0.5rem;
padding-bottom: 1rem;

div{
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  cursor: pointer;
}
`