
import { Navigate } from "react-router-dom";
import { parseCookies } from "nookies";
import Utils from "../../services/utils";

interface IAuthGuardProps {
  children: JSX.Element
}
// Redirects to Home if there's access token
// Redirection to login is done by HttpInterceptor
// Todo: analyse if HomeGuard is a better name
export function AuthGuard(props: IAuthGuardProps) {
  const { children } = props;

  const accessToken = Utils.getCookie('sensar@token');
  const userToken = Utils.getCookie('sensar@user');

  if (accessToken == null || userToken == null) {
    return (children);
  }

  if (children.type.name === "Login") {
    return (<Navigate to="/home"></Navigate>);
  }
  return (children);

}