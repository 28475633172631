import { ChakraProvider } from "@chakra-ui/react";
import { SideBarDrawerProvider } from "./contexts/SideBarContext";
import { Routes } from './routes/Routes'
import { BrowserRouter as Router } from "react-router-dom";
import { HttpInterceptor } from "./services/httpInterceptor";
import { AuthProvider } from "./contexts/authContext";
import { ThemeProvider } from "styled-components";
import { chakraTheme } from "./styles/chakraTheme";
import { theme } from "./styles/theme";
import { ModalProvider } from 'styled-react-modal'

export default function App() {
  return (
    <ChakraProvider theme={chakraTheme} >
      <ThemeProvider theme={theme}>
        <ModalProvider>

          <Router>
            <AuthProvider>
              <HttpInterceptor>

                <SideBarDrawerProvider>

                  <Routes />

                </SideBarDrawerProvider>

              </HttpInterceptor>
            </AuthProvider>

          </Router>

        </ModalProvider>
      </ThemeProvider>
    </ChakraProvider>
  );
}