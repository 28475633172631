import { useDisclosure, UseDisclosureReturn } from "@chakra-ui/react";
import { createContext, ReactNode, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

const SideBarDrawerContext = createContext({})
interface ISidebarProps {
	children: JSX.Element
}
export function SideBarDrawerProvider({ children }: ISidebarProps) {
	const disclosure = useDisclosure()
	const location = useLocation()

	useEffect(() => {
		disclosure.onClose()
	}, [location.pathname])

	return (
		<SideBarDrawerContext.Provider value={disclosure}>
			{children}
		</SideBarDrawerContext.Provider>
	)
}

export const useSidebarDrawer = () => useContext(SideBarDrawerContext)