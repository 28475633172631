import Loading from '../../Loading/Loading';
import { StyledButton } from './Primary.styles';
interface IPrimaryButtonProps {
	title: string;
	loading: boolean;
	attributes: any;
}

export function PrimaryButton({ title, loading = false, attributes }: IPrimaryButtonProps) {
	
	function buttonContent() {
		if (loading) {
			return (<Loading color='white'/>);
		}
		else return title;
	}

	return (
		<StyledButton $loading={loading} {...attributes}>
			{buttonContent()}
		</StyledButton>
	);
}