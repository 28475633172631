import styled from "styled-components";

export const PopoverTitle = styled.span`
  color: ${props => props.theme.popover.text};
  font-size: ${props => props.theme.popover.titleFontSize};
  font-weight: ${props => props.theme.popover.titleFontWeight};
`

export const MainText = styled.span`
  padding-top: 1rem;
`

export const BoldText = styled.span`
  padding-top: 1rem;
  font-weight: bold;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
`
