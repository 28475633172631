import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { StyledModal, HeaderCloseButton, ModalHeader, StyledModalFooter, ModalTitle } from "./ThemeModal.styles";

interface IModalProps {
  isOpen: boolean;
  title?: string;
  children?: JSX.Element;
  footer?: JSX.Element;
  onModalClose: () => void;
}
export default function ThemeModal(props: IModalProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  function close() {
    setIsOpen(false);
    props.onModalClose();
  }

  return (
    <StyledModal isOpen={isOpen}>
      <ModalHeader>
        <ModalTitle>{props.title}</ModalTitle>
        <HeaderCloseButton as={FaTimes} onClick={() => close()}></HeaderCloseButton>
      </ModalHeader>

      {props.children}

      {ModalFooter(props.footer)}

    </StyledModal>
  );

}

function ModalFooter(footerContent?: JSX.Element) {
  if (footerContent != null) {
    return (
      <StyledModalFooter>
        {footerContent}
      </StyledModalFooter>
    );
  }
  else return;
}