import { RiTempColdLine, RiFlashlightLine } from "react-icons/ri";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Flex } from "../../../components/Flex/Flex.styles";
import {
  HeaderContainer,
  BackButton,
  HeaderTitle,
  HeaderSubtitle,
  VoltageButton,
} from "./Header.styles";
import { useMediaQuery } from "@react-hook/media-query";
import { breakpoint } from "../../../styles/theme";

interface IHeaderProps {
  macAddress?: string;
  name?: string;
  temperature?: number;
}
export function Header(props: IHeaderProps) {
  const { macAddress, name, temperature } = props;

  const navigate = useNavigate();

  const isMobile = useMediaQuery(`(max-width: ${breakpoint.sm})`);

  if (isMobile) {
    return (
      <HeaderContainer>
        <Flex className="column">
          <HeaderTitle>{name}</HeaderTitle>
          <HeaderSubtitle>{macAddress}</HeaderSubtitle>
        </Flex>
        <Flex className="justify-content-end" $columnGap="0.5rem">
          {temperatureCard(temperature)}
        </Flex>
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer className="justify-content-between align-items-center">
      <Flex $flex="0 0 195px">
        <BackButton onClick={goBack}>
          <FaArrowLeft color="white" />
        </BackButton>
      </Flex>

      <Flex className="column">
        <HeaderTitle>{name}</HeaderTitle>
        <HeaderSubtitle>{macAddress}</HeaderSubtitle>
      </Flex>

      <Flex
        className="justify-content-end"
        $flex="0 0 195px"
        $columnGap="0.5rem"
      >
        {temperatureCard(temperature)}
      </Flex>
    </HeaderContainer>
  );

  function goBack() {
    navigate("/");
  }
}

function temperatureCard(temperature?: number) {
  let valueLabel = "-- º C";
  if (temperature != null) {
    valueLabel = `${Number(temperature).toFixed(2)} º C`;
  }
  return (
    <Flex className="justify-content-between align-items-center">
      <VoltageButton>
        <RiTempColdLine color="#FFF" />
        {valueLabel}
      </VoltageButton>
    </Flex>
  );
}
