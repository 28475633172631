import { Dispatch, SetStateAction } from "react";
import { Axis } from "../../constants/Chart.enum";

export interface ChartXAxisRange {
  begin: number;
  end: number;
  axisType: 'date' | 'number';
}

export function setMultiAxisLimitDataset(chartXAxisRange: ChartXAxisRange, limit: number | null, direction: 'upper' | 'lower', selectedAxis: number, setDatasetFn: Dispatch<SetStateAction<{}>>) {
  
  if (limit == null || isNaN(limit)) {
    setDatasetFn({});
    return;
  }

  setDatasetFn({
    type: 'line',
    legendText: 'Limite' + (direction === 'upper' ? ' Superior ' : ' Inferior ') + Axis[selectedAxis],
    name: 'Limite',
    showInLegend: true,
    markerType: "none",
    legendMarkerType: direction === 'upper' ? "triangle" : "cross",
    fillOpacity: .15,
    color: 'red',
    dataPoints: [
      { y: limit, x: chartXAxisRange.axisType === 'date' ? new Date(chartXAxisRange.begin) : chartXAxisRange.begin },
      { y: limit, x: chartXAxisRange.axisType === 'date' ? new Date(chartXAxisRange.end) : chartXAxisRange.end }
    ]
  });
}

export function getSingleAxisDataset(data: number[], label: string, lineColor: string) {
  return {
    data: data,
    borderColor: lineColor,
    pointStyle: false,
    label: label,
    backgroundColor: lineColor,
  };
};