/* #region Button */
interface IconOnlyButton {
  fontSize: string;
}
interface IconOnlyFilledButton {
  fontSize: string;
}
interface ILinkButton {
  color: string;
}
interface IconOnlyOutlinedButton {
  size: string;
}
interface IOutlinedButton {
  border: string;
  borderColorHovered: string;
  borderColorFocused: string;
  borderColorActive: string;
  iconOnlyButtonOutlined: IconOnlyOutlinedButton;
}
interface ISecondaryButton {
  backgroundColor: string;
  color: string;
  backgroundColorActive: string;
  backgroundColorHovered: string;
  backgroundColorFocused: string;
  border: string;
  borderColorHovered: string;
  borderColorActive: string;
}
interface IButtonTheme {
  color: string;
  backgroundColor: string;
  backgroundColorHovered: string;
  backgroundColorFocused: string;
  backgroundColorActive: string;
  border: string;
  borderColor?: string;
  borderRadius: string;
  borderColorHovered: string;
  borderColorFocused: string;
  borderColorActive: string;
  padding: string;
  outlinedButton: IOutlinedButton;
  iconOnlyButton: IconOnlyButton;
  iconOnlyFilledButton: IconOnlyFilledButton;
  linkButton: ILinkButton;
  secondaryButton: ISecondaryButton
}
/* #endregion */

/* #region Input */
interface IInputTheme {
  color: string;
  backgroundColor: string;
  fontSize: string;
  borderColor: string;
  borderColorHovered: string;
  borderColorFocused: string;
  borderColorError: string;
  borderRadius: string;
  padding: string;
}
/* #endregion */

/* #region Modal */
interface IModalTheme {
  closeButtonColor: string;
  closeButtonFontSize: string;
  backgroundColor: string;
  padding: string;
  borderRadius: string;
}
/* #endregion */

interface IPopoverTheme {
  backgroundColor: string;
  padding: string;
  borderRadius: string;
  border: string;
  color: string;
  titleFontSize: string;
  titleFontWeight: string;
}

interface IChartTheme {
  backgroundColor: string;
  singleAxisLineColor: string;
  gridColor: string;
  tickColor: string;
  limitLineColor: string;
}

interface ILoadingTheme{
  color: string;
}
interface IDatePickerTheme{
  color: string;
  hoverColor: string;
  loadingLabelColor: string;
}

export interface ITheme {
  button: IButtonTheme;
  input: IInputTheme;
  modal: IModalTheme;
  chart: IChartTheme;
  popover: IPopoverTheme;
  loading: ILoadingTheme;
  datePicker: IDatePickerTheme;
};

const colorPallete = {
  red: {
    'primary': "#E95E5E",
    'primary-600': "#BF3434",
    'primary-700': "#992929",
    'secondary': "#DB6262",
    'error': "#E53E3E"
  },
  blue: {
    'primary': "rgb(79, 129, 188)",
    'primary-100': "#279CE6"
  },
  black: {
    1: "#353646",
    'background-1': "#181B23",
    'background-2': "#2B2C37",
    'background-3': "#333441",
    'background-4': "#212122",
  },
  gray: {
    1: "#94A3B8",
    'primary': "#DBDCDF",
    'primary-600': "#BCBCBD",
    'primary-700': "#ABABAE",
    'primary-800': "#4b4d63"
  },
  white: {
    'primary': "white",
    'primary-100': "#E1E2E3",
    'text': "#EEEEF2"
  }
}
export const theme: ITheme = {
  button: {
    color: colorPallete.white['text'],
    backgroundColor: colorPallete.red['primary'],
    backgroundColorHovered: colorPallete.red['primary-600'],
    backgroundColorFocused: colorPallete.red['primary-700'],
    backgroundColorActive: colorPallete.red['primary-700'],
    border: `1px solid ${colorPallete.red['primary']}`,
    borderColorHovered: colorPallete.red['primary-600'],
    borderColorFocused: colorPallete.red['primary-700'],
    borderColorActive: colorPallete.red['primary-700'],
    borderRadius: "0.375rem",
    padding: '0.5rem;',
    secondaryButton: {
      backgroundColor: colorPallete.gray['primary'],
      color: colorPallete.black['background-3'],
      backgroundColorHovered: colorPallete.gray['primary-600'],
      backgroundColorActive: colorPallete.gray['primary-700'],
      backgroundColorFocused: colorPallete.gray['primary-700'],
      border: `1px solid ${colorPallete.gray['primary']}`,
      borderColorHovered: colorPallete.gray['primary-600'],
      borderColorActive: colorPallete.gray['primary-700'],
    },
    outlinedButton: {
      border: `1px solid ${colorPallete.black[1]}`,
      borderColorHovered: colorPallete.gray[1],
      borderColorFocused: colorPallete.red['secondary'],
      borderColorActive: colorPallete.red['secondary'],
      iconOnlyButtonOutlined: {
        size: '2rem',
      },
    },
    iconOnlyButton: {
      fontSize: '1.2rem',
    },
    iconOnlyFilledButton: {
      fontSize: "2.5rem"
    },
    linkButton: {
      color: colorPallete.red['secondary']
    }
  },
  input: {
    color: colorPallete.white['text'],
    backgroundColor: "#181B23",
    fontSize: "1rem",
    borderColor: colorPallete.black[1],
    borderColorHovered: colorPallete.gray[1],
    borderColorFocused: colorPallete.red['secondary'],
    borderColorError: colorPallete.red['error'],
    borderRadius: "0.375rem",
    padding: "0.25rem 0.5rem"
  },
  modal: {
    closeButtonColor: 'white',
    closeButtonFontSize: '1.4rem',
    backgroundColor: colorPallete.black['background-4'],
    padding: '1rem',
    borderRadius: '0.375rem'
  },
  chart: {
    backgroundColor: colorPallete.black['background-3'],
    singleAxisLineColor: colorPallete.blue['primary'],
    gridColor: colorPallete.gray['primary-800'],
    tickColor: colorPallete.white['primary-100'],
    limitLineColor: colorPallete.red['primary'],
  },
  popover: {
    backgroundColor: colorPallete.black['background-4'],
    padding: '20px 10px',
    border: `1px solid ${colorPallete.gray['1']}`,
    borderRadius: '0.3rem',
    color: colorPallete.white['text'],
    titleFontSize: '1.2rem',
    titleFontWeight: '600',
  },
  loading: {
    color: colorPallete.red['primary']
  },
  datePicker: {
    color: colorPallete.white['text'],
    hoverColor: colorPallete.gray['primary-700'],
    loadingLabelColor: colorPallete.blue['primary-100'],
  }
}

export const breakpoint = {
  sm: '500px',
  md: '980px',
};