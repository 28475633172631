import styled from "styled-components";
import Modal from "styled-react-modal";
import { ThemeIconOnlyButton } from "../Buttons/IconOnly/ThemeIconOnly.styles";
import { Flex } from "../Flex/Flex.styles";

export const StyledModal = Modal.styled`
  display: flex;  
  flex-direction: column; 

  max-height: min(90vh, 600px);
  max-width: min(90vw, 1000px);
  min-width: 200px;

  background-color: ${(props: any) => props.theme.modal.backgroundColor};
  padding: ${(props: any) => props.theme.modal.padding};
  border-radius: ${(props: any) => props.theme.modal.borderRadius};
`;
export const ModalHeader = styled(Flex).attrs(props => ({
  className: 'justify-content-between align-items-center'
}))`
  padding-bottom: 1rem;
  column-gap: 1rem;
`;
export const ModalTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
`
export const HeaderCloseButton = styled(ThemeIconOnlyButton)`
  color: ${(props:any) => props.theme.modal.closeButtonColor};
  font-size: ${(props:any) => props.theme.modal.closeButtonFontSize};
`
export const StyledModalFooter = styled.div`
  padding: 1rem 0.5rem 0;
  display: flex;
  justify-content: center;
`; 