import styled from "styled-components";
import { breakpoint } from "../../styles/theme";

export const BodyContainer = styled.div`
	border-radius: 8px;
	padding: 1.5rem;
	@media (max-width: ${breakpoint.sm}){
		padding: 0.5rem 0.5rem 0;
	}
	background-color: var(--background-black-2);
`;