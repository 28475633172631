import styled from "styled-components";
import { Flex } from '../../components/Flex/Flex.styles';
import { breakpoint } from "../../styles/theme";


export const Layout = styled(Flex).attrs(props => ({
	className: 'w-100 h-100 align-items-center justify-content-center'
}))``;


export const LoginForm = styled.form`
	display: flex;
	flex-direction: column;
	width: 600px;
	border-radius: 8px;
	background-color: #1f2029;
	padding: 3rem;

	@media (max-width: ${breakpoint.sm}) {
		width: 100%;
		height: 100%;
		padding: 1.5rem;
	}
`
