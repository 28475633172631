import { useEffect, useState } from 'react';
import CanvasJSReact from '../../canvas/canvasjs.react';
import { ChartXAxisRange, setMultiAxisLimitDataset } from './Chart';
import { useTheme } from 'styled-components';
import { ITheme } from '../../styles/theme';
import { differenceInDays } from 'date-fns';
import { Control, useWatch } from 'react-hook-form';
import { LimitFormData } from '../../pages/Dashboard/ChartArea/ChartLimit/ChartLimit';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
interface DateAxisStyle {
  interval?: number;
  intervalType?: string;
  valueFormatString: string;
}
interface DateChartProps {
  data: {
    xValue: number[];
    yValue: number[];
    zValue: number[];
    date: number[];
  };
  labelX: string;
  labelY: string;
  selectedAxis: number;
  limitFormControl: Control<LimitFormData, any>;
}
const CHART_TYPE = 'line';

export function DateChart({ data, labelX, labelY, selectedAxis, limitFormControl }: DateChartProps) {
  const theme = useTheme() as ITheme;
  const [dateAxisStyle, setDateAxisStyle] = useState<DateAxisStyle>({
    interval: 1,
    intervalType: 'day',
    valueFormatString: "DD MMM YYYY"
  });

  const upperLimit = useWatch({control: limitFormControl, name: 'upperLimit'});
  const lowerLimit = useWatch({control: limitFormControl, name: 'lowerLimit'});

  useEffect(() => {
    const firstDate = data.date[0];
    const lastDate = data.date[data.date.length - 1];
    const dateRangeInDays = differenceInDays(new Date(firstDate), new Date(lastDate))

    if (dateRangeInDays < 2) {
      setDateAxisStyle({
        valueFormatString: "DD MMM YYYY HH:mm"
      })
      return;
    }
    setDateAxisStyle({
      interval: 1,
      intervalType: 'day',
      valueFormatString: "DD MMM YYYY"
    })
  }, [data]);

  const [upperLimitDatasetOptions, setUpperLimitDatasetOptions] = useState({});
  const [lowerLimitDatasetOptions, setLowerLimitDatasetOptions] = useState({});

  useEffect(() => {
    const xAxisRange: ChartXAxisRange = {
      begin: data.date[0],
      end: data.date[data.date.length - 1],
      axisType: 'date'
    }
    setMultiAxisLimitDataset(xAxisRange, upperLimit, 'upper', selectedAxis, setUpperLimitDatasetOptions);
  }, [upperLimit, selectedAxis]);

  useEffect(() => {
    const xAxisRange: ChartXAxisRange = {
      begin: data.date[0],
      end: data.date[data.date.length - 1],
      axisType: 'date'
    }
    setMultiAxisLimitDataset(xAxisRange, lowerLimit, 'lower', selectedAxis, setLowerLimitDatasetOptions);
  }, [lowerLimit, selectedAxis]);

  return (
    <CanvasJSChart options={{
      animationEnabled: true,
      backgroundColor: theme.chart.backgroundColor,
      colorSet: '#FFFFFF',
      theme: "dark2",
      zoomEnabled: true,
      axisX: {
        title: labelX,
        gridThickness: 0,
        ...dateAxisStyle
      },
      axisY: {
        title: labelY,
        gridThickness: 1,
        gridColor: '#4b4d63',
        includeZero: true,
        minimum: 0,
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: function (e: any) {
          if (
            typeof e?.dataSeries?.visible === "undefined" ||
            e?.dataSeries?.visible
          ) {
            e.dataSeries.visible = false;
          } else {
            e.dataSeries.visible = true;
          }
          e.chart.render();
        },
      },
      data: [
        {
          type: CHART_TYPE,
          legendText: 'Eixo X',
          name: 'Eixo X',
          showInLegend: true,
          xValueFormatString: "DD/MM/YYYY HH:mm",
          dataPoints: data?.xValue?.map((item: any, index: number) => {
            return { y: item, x: new Date(data.date[index]) }
          })
        },
        {
          type: CHART_TYPE,
          legendText: 'Eixo Y',
          name: 'Eixo Y',
          showInLegend: true,
          xValueFormatString: "DD/MM/YYYY HH:mm",
          dataPoints: data?.yValue.map((item, index) => {
            return { y: item, x: new Date(data.date[index]) }
          })
        },
        {
          type: CHART_TYPE,
          legendText: 'Eixo Z',
          name: 'Eixo Z',
          showInLegend: true,
          xValueFormatString: "DD/MM/YYYY HH:mm",
          dataPoints: data.zValue.map((item, index) => {
            return { y: item, x: new Date(data.date[index]) }
          })
        },
        upperLimitDatasetOptions,
        lowerLimitDatasetOptions,
      ]
    }}
    />
  )
}
