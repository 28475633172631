import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { format, isSameDay, startOfYesterday } from "date-fns";

// Models
import { ChartView, LimitValue } from "./Dashboard.types";

// Services

/* #region Components */
import { BodyContainer } from "./Dashboard.styles";
import { Header } from "./Header/Header";
import { Filter } from "./filter/Filter";
import { ChartArea } from "./ChartArea/ChartArea";
import { HomeLayout } from "../../components/HomeLayout/HomeLayout";
import texts from "../../constants/texts";
import accelerationService from "../../services/http/acceleration.service";
import limitService from "../../services/http/limit.service";
import sensorService from "../../services/http/sensor.service";
import { AccelerationDateResponse } from "../../services/responses/acceleration-date-response.dto";
import { SensorDataResponseDTO, SensorTemperatureResponseDTO } from "../../services/responses/sensor-data-response.dto";
import Utils from "../../services/utils";
import { CreateSensorLimitDTO } from "../../services/dto/create-sensor-limit.dto";
import { Messages } from "../../services/messages";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Indicator, NewIndicatorEnum } from "../../constants/Chart.enum";
import { ChartTitle } from "./ChartTitle/ChartTitle";
import { SensorResponseDto } from "../../services/responses/sensor-response.dto";
import outlierDetectionService from "../../services/http/outlier-detection.service";
import { CreateOutlierConfigDto } from "../../services/dto/create-outlier-config.dto";
/* #endregion */

const CHART_DAY_RANGE = 7;
export function Dashboard() {
  const { sensorMacAddress } = useParams();
  const {
    pages: {
      dashboard: {
        charts: { acceleration },
      },
    },
  } = texts;

  const [sensorData, setsensorData] = useState<SensorResponseDto | undefined>(undefined);

  // Date filter
  const [asyncDates, setAsyncDates] = useState<AccelerationDateResponse[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();

  // Charts & Limits
  const [chartViewList, setChartViewList] = useState<ChartView[]>(initChartSelection());
  const [temperatureLimit, setTemperatureLimit] = useState<LimitValue>();

  // Dashboard data 
  const [sensorAccelerationData, setSensorAccelerationData] = useState<SensorDataResponseDTO | undefined>(undefined);
  const [sensorScalarData, setSensorScalarData] = useState<SensorDataResponseDTO | undefined>(undefined);

  // Temperature data
  const [sensorTemperatureData, setSensorTemperatureData] = useState<SensorTemperatureResponseDTO | undefined>(undefined);

  const [accelerationDataLoading, setAccelerationDataLoading] = useState(true);
  const [accelerationScalarLoading, setAccelerationScalarLoading] = useState(true);
  const [temperatureDataLoading, setTemperatureDataLoading] = useState(true);
  const [accelerationDatesLoading, setAccelerationDatesLoading] = useState(true);

  useEffect(() => {
    if (sensorMacAddress == "" || sensorMacAddress == null) {
      return;
    }
    // Load sensor data
    sensorService.getSensorByMacAddress(sensorMacAddress).then(response => {
      setsensorData(response.data);
      getSensorLimits(response.data.id);
      getSensorOutlierConfig(response.data.id);
    });

    // Load temperature data
    setTemperatureDataLoading(true);
    sensorService.getTemperatureData(sensorMacAddress, undefined, CHART_DAY_RANGE).then(response => {
      setSensorTemperatureData(response.data);
      setTemperatureDataLoading(false);
    });



    // Load acceleration scalar data
    setAccelerationScalarLoading(true);
    sensorService.getAccelerationScalarData(sensorMacAddress, CHART_DAY_RANGE).then(response => {
      setSensorScalarData(response.data);
      setAccelerationScalarLoading(false);
    });

    // Load acceleration dates
    setAccelerationDatesLoading(true);
    accelerationService.accelerationDates(sensorMacAddress).then(async response => {
      const newAccelerationDates = response.data;
      newAccelerationDates.forEach((item) => {
        item.date = new Date(item.date);
      });

      const lastAccelerationDate = Utils.Last(newAccelerationDates);

      setAsyncDates(asyncDates.concat(newAccelerationDates));
      setSelectedDate(lastAccelerationDate?.date);
      setAccelerationDatesLoading(false);

      // Load acceleration data
      setAccelerationDataLoading(true);
      sensorService.getAccelerationData(sensorMacAddress, lastAccelerationDate?.id).then(response => {
        setSensorAccelerationData(response.data);
        setAccelerationDataLoading(false);
      });
    });
  }, []);

  // On filter change
  async function onSelectedDateChange(date: Date) {
    const filteredTimes = asyncDates
      .filter((x) => isSameDay(x.date, date))
      .sort((a, b) => a.date.getTime() - b.date.getTime());
    setSelectedDate(Utils.Last(filteredTimes)?.date);

    // Load sensor data
    setAccelerationDataLoading(true);
    const lastTimeOfTheDay = Utils.Last(filteredTimes) as AccelerationDateResponse;
    sensorService.getAccelerationData(sensorMacAddress!, lastTimeOfTheDay.id)
      .then(
        (response) => {
          const sensorData = response.data;
          setSensorAccelerationData(sensorData);
        },
        (error) => console.error(error)
      )
      .finally(() => {
        setAccelerationDataLoading(false);
      });

    // Load sensor secondary data
    setAccelerationScalarLoading(true);
    sensorService.getAccelerationScalarData(sensorMacAddress!, CHART_DAY_RANGE, lastTimeOfTheDay.id).then(response => {
      setSensorScalarData(response.data);
      setAccelerationScalarLoading(false);
    });

    // Load temperature data
    setTemperatureDataLoading(true);
    sensorService.getTemperatureData(sensorMacAddress!, lastTimeOfTheDay.date, CHART_DAY_RANGE).then(response => {
      setSensorTemperatureData(response.data);
      setTemperatureDataLoading(false);
    });

    // setChartViewList(OldhideChartsWithoutData(chartViewList, vibrationData))
  }
  async function onSelectedMonthChange(newMonth: Date) {
    setAccelerationDatesLoading(true);
    accelerationService.accelerationDates(sensorMacAddress ?? "", newMonth.getMonth() + 1).then(response => {
      const newAccelerationDates = response.data;
      newAccelerationDates.forEach((item) => {
        item.date = new Date(item.date);
      });

      setAsyncDates(asyncDates.concat(newAccelerationDates));
      setAccelerationDatesLoading(false);
    });
  }
  async function onTimeChange(accelerationDate: AccelerationDateResponse) {
    setSelectedDate(accelerationDate.date);

    // Load sensor secondary data
    setAccelerationScalarLoading(true);
    sensorService.getAccelerationScalarData(sensorMacAddress!, CHART_DAY_RANGE, accelerationDate.id).then(response => {
      setSensorScalarData(response.data);
      setAccelerationScalarLoading(false);
    });

    // Load temperature data
    setTemperatureDataLoading(true);
    sensorService.getTemperatureData(sensorMacAddress!, accelerationDate.date, CHART_DAY_RANGE).then(response => {
      setSensorTemperatureData(response.data);
      setTemperatureDataLoading(false);
    });

    // Load sensor data
    setAccelerationDataLoading(true);
    sensorService.getAccelerationData(sensorMacAddress!, accelerationDate.id)
      .then(
        (response) => {
          const sensorData = response.data;
          setSensorAccelerationData(sensorData);
        },
        (error) => console.error(error)
      )
      .finally(() => {
        setAccelerationDataLoading(false);
      });
    // setChartViewList(OldhideChartsWithoutData(chartViewList, vibrationData))
  }
  function onTabVisibilityChange(chartViewList: ChartView[]) {
    setChartViewList(chartViewList);
  }

  async function getSensorLimits(sensorId: string) {
    const response = await limitService.listSensorLimits(sensorId);
    const newChartViewList: ChartView[] = [...chartViewList]

    // Get the limits for each indicator
    for (const item of newChartViewList) {
      const indicatorLimit = response.data.find(x => x.indicator === item.indicator)
      if (indicatorLimit != null) {
        item.upperLimit = {
          x: indicatorLimit.xAxis?.maxValue ?? null,
          y: indicatorLimit.yAxis?.maxValue ?? null,
          z: indicatorLimit.zAxis?.maxValue ?? null,
        };
        item.lowerLimit = {
          x: indicatorLimit.xAxis?.minValue ?? null,
          y: indicatorLimit.yAxis?.minValue ?? null,
          z: indicatorLimit.zAxis?.minValue ?? null,
        };
      }
      else {
        item.upperLimit = {
          x: null,
          y: null,
          z: null,
        };
        item.lowerLimit = {
          x: null,
          y: null,
          z: null,
        };
      }
    }
    setChartViewList(newChartViewList)

    // Get the temperature limit
    const temperatureLimit = response.data.find(x => x.indicator === Indicator.TEMPERATURE)
    if (temperatureLimit != null) {
      setTemperatureLimit({
        minValue: temperatureLimit.singleAxis?.minValue ?? null,
        maxValue: temperatureLimit.singleAxis?.maxValue ?? null,
      })
    }

  }
  async function getSensorOutlierConfig(sensorId: string) {
    const response = await outlierDetectionService.listSensorOutlierConfig(sensorId);
    const newChartViewList: ChartView[] = [...chartViewList];

    for (const item of newChartViewList) {
      const indicatorConfig = response.data.find(x => x.indicator.id === item.indicatorId)
      item.outlierDetectionEnabled = indicatorConfig?.isOutlierDetectionEnabled ?? false;
    }
    setChartViewList(newChartViewList)
  }

  async function onChartLimitSave(isMultiLimit: boolean, indicator: string, limitValue: LimitValue, axis?: number,) {
    const sensorId = sensorData?.id;
    if (sensorId == null) {
      return;
    }

    const requestDTO: CreateSensorLimitDTO = {
      indicator: indicator,
      sensorId: sensorId,
    }
    if (isMultiLimit) {
      if (axis === 0) {
        requestDTO.xAxis = {
          minValue: limitValue.minValue,
          maxValue: limitValue.maxValue,
        }
      }
      if (axis === 1) {
        requestDTO.yAxis = {
          minValue: limitValue.minValue,
          maxValue: limitValue.maxValue,
        }
      }
      if (axis === 2) {
        requestDTO.zAxis = {
          minValue: limitValue.minValue,
          maxValue: limitValue.maxValue,
        }
      }
    }
    else {
      requestDTO.singleAxis = {
        minValue: limitValue.minValue,
        maxValue: limitValue.maxValue,
      }
    }

    try {
      await limitService.saveSensorLimit(requestDTO);
      await getSensorLimits(sensorId);

      toast.success(Messages["limit-success"], {
        position: "top-right"
      });
    } catch (error: any) {
      console.error(error);

      const errorMessage = axios.isAxiosError(error) ?
        error.response?.data?.message : error;

      toast.error(errorMessage, {
        position: "top-right"
      });
    }
  }
  async function onOutlierConfigSave(isOutlierDetectionEnabled: boolean, tabIndex: number) {
    const sensorId = sensorData?.id;
    if (sensorId == null) {
      return;
    }

    const indicator = chartViewList[tabIndex].indicatorId;

    const requestDTO: CreateOutlierConfigDto = {
      indicatorId: indicator,
      sensorId,
      isOutlierDetectionEnabled
    }

    try {
      await outlierDetectionService.saveOutlierConfig(requestDTO);
      await getSensorOutlierConfig(sensorId);

      toast.success(Messages["config-success"], {
        position: "top-right"
      });
    } catch (error: any) {
      console.error(error);

      const errorMessage = axios.isAxiosError(error) ?
        error.response?.data?.message : error;

      toast.error(errorMessage, {
        position: "top-right"
      });
    }
  }

  return (
    <HomeLayout>
      <BodyContainer>
        <Header
          macAddress={sensorData?.macAddress}
          name={sensorData?.name}
          temperature={sensorTemperatureData?.lastTemperature?.value}
        ></Header>

        <Filter
          isLoading={accelerationDatesLoading}
          accelerationDates={asyncDates}
          selectedDate={selectedDate}
          onSelectedTimeChange={onTimeChange}
          onSelectedDateChange={onSelectedDateChange}
          onFilterMonthChange={onSelectedMonthChange}
          chartViewList={chartViewList}
          onTabVisibilityChange={onTabVisibilityChange}
        ></Filter>

        <ChartTitle title={acceleration.title.replace(
          "{0}",
          `${format(
            selectedDate ?? startOfYesterday(),
            "dd/MM/yyyy 'às' HH:mm'h'"
          )}`
        )} isLoading={selectedDate === undefined}>
        </ChartTitle>

        <ChartArea
          loadingSources={{
            accelerationData: accelerationDataLoading,
            accelerationScalarData: accelerationScalarLoading,
            temperatureData: temperatureDataLoading
          }}
          accelerationData={sensorAccelerationData}
          accelerationScalarData={sensorScalarData}
          temperatureData={sensorTemperatureData?.temperatures}

          chartViewList={chartViewList}
          temperatureLimit={temperatureLimit}
          onChartLimitSave={onChartLimitSave}

          onOutlierConfigSave={onOutlierConfigSave}
        ></ChartArea>

        <Toaster />

      </BodyContainer>
    </HomeLayout>
  );

}

function initChartSelection(): ChartView[] {
  // TODO: Em vez de iterar esse enum vei, tem que pegar a lista de indicadores do backend
  // Limits estão usando o indicador antigo (só string), tem que mudar pra usar o novo (tabela)
  // Tbm ajustar a ordenação.
  // const {
  //   pages: {
  //     dashboard: {
  //       charts: { tabs },
  //     },
  //   },
  // } = texts;
  // const chartViewList: ChartView[] = [];
  // let i = 1;
  // for (const [index, iterator] of Object.entries(Indicator)) {
  //   chartViewList.push({
  //     isVisible: true,
  //     name: tabs[iterator],
  //     indicator: iterator.toString(),
  //     indicatorId: i,
  //     disabled: false,
  //     upperLimit: { x: null, y: null, z: null },
  //     lowerLimit: { x: null, y: null, z: null },
  //     outlierDetectionEnabled: false
  //   });
  //   i++;
  // }
  // return chartViewList;
  return [
    { isVisible: true, name: "Aceleração RMS", indicator: Indicator.ACCELERATION_RMS, disabled: false, upperLimit: { x: null, y: null, z: null }, lowerLimit: { x: null, y: null, z: null }, outlierDetectionEnabled: true, indicatorId: NewIndicatorEnum.ACCELERATION_RMS },
    { isVisible: true, name: "Fator de Crista", indicator: Indicator.CREST_FACTOR, disabled: false, upperLimit: { x: null, y: null, z: null }, lowerLimit: { x: null, y: null, z: null }, outlierDetectionEnabled: true, indicatorId: NewIndicatorEnum.CREST_FACTOR },
    { isVisible: true, name: "Desvio Padrão", indicator: Indicator.STANDARD_DEVIATION, disabled: false, upperLimit: { x: null, y: null, z: null }, lowerLimit: { x: null, y: null, z: null }, outlierDetectionEnabled: true, indicatorId: NewIndicatorEnum.STANDARD_DEVIATION },
    { isVisible: true, name: "Velocidade RMS", indicator: Indicator.VELOCITY_RMS, disabled: false, upperLimit: { x: null, y: null, z: null }, lowerLimit: { x: null, y: null, z: null }, outlierDetectionEnabled: true, indicatorId: NewIndicatorEnum.VELOCITY_RMS },
    { isVisible: true, name: "Aceleração", indicator: Indicator.ACCELERATION, disabled: false, upperLimit: { x: null, y: null, z: null }, lowerLimit: { x: null, y: null, z: null }, outlierDetectionEnabled: true, indicatorId: NewIndicatorEnum.ACCELERATION },
    { isVisible: true, name: "Aceleração FFT", indicator: Indicator.ACCELERATION_FFT, disabled: false, upperLimit: { x: null, y: null, z: null }, lowerLimit: { x: null, y: null, z: null }, outlierDetectionEnabled: true, indicatorId: NewIndicatorEnum.ACCELERATION_FFT },
    { isVisible: true, name: "Velocidade", indicator: Indicator.VELOCITY, disabled: false, upperLimit: { x: null, y: null, z: null }, lowerLimit: { x: null, y: null, z: null }, outlierDetectionEnabled: true, indicatorId: NewIndicatorEnum.VELOCITY },
    { isVisible: true, name: "Velocidade FFT", indicator: Indicator.VELOCITY_FFT, disabled: false, upperLimit: { x: null, y: null, z: null }, lowerLimit: { x: null, y: null, z: null }, outlierDetectionEnabled: true, indicatorId: NewIndicatorEnum.VELOCITY_FFT },
    { isVisible: true, name: "Deslocamento", indicator: Indicator.DISPLACEMENT, disabled: false, upperLimit: { x: null, y: null, z: null }, lowerLimit: { x: null, y: null, z: null }, outlierDetectionEnabled: true, indicatorId: NewIndicatorEnum.DISPLACEMENT },
  ];
}

