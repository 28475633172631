import styled from "styled-components";
import { Flex } from "../../../components/Flex/Flex.styles";
import { breakpoint } from "../../../styles/theme";

export const HeaderContainer = styled(Flex)`
width: 100%;
padding-bottom: 1.4rem;
align-items: center;
justify-content: space-between;

@media (max-width: ${breakpoint.sm}){
	flex-direction: column;
	justify-content: flex-start;
	row-gap: 1rem;
	padding-bottom: 2rem;
}
`;

export const TitleContainer = styled(Flex)`
flex-grow: 1;
text-align: center;
`;

export const HeaderTitle = styled.div`
	font-size: 2.5rem;
	color: var(--font-white);
	font-weight: 600;
	text-align: center;
`;

export const HeaderSubtitle = styled.div`
	font-size: 1.5rem;
	color: #279CE6;
	font-weight: 600;
	text-align: center;
`;

export const BackButton = styled.button`
	width: 195
	font-size: 1.5rem;
	color: var(--font-white);
`;

// TODO: this looks like a good template button
export const VoltageButton = styled.button`
	display: flex;
	column-gap: 0.5rem;
	align-items: center;

	font-size: 0.9rem;
	font-weight: 600;
	padding: 0.3rem 0.8rem;
	white-space: nowrap;
	color: white;
	background-color: var(--blue-2);
	border-radius: 0.375rem;
	cursor: default;

	&:hover{
	background-color: var(--blue-3);
	}
`;




