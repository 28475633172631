import { useEffect, useState } from "react";
import CanvasJSReact from "../../canvas/canvasjs.react";
import { ChartXAxisRange, setMultiAxisLimitDataset } from "./Chart";
import { useTheme } from "styled-components";
import { Control, useWatch } from "react-hook-form";
import { LimitFormData } from "../../pages/Dashboard/ChartArea/ChartLimit/ChartLimit";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const CHART_Y_FORMAT = '0.00000';

interface FFTChartProps {
  data: {
    xValue: number[];
    yValue: number[];
    zValue: number[];
    frequencies: number[];
  };
  labelX: string;
  labelY: string;
  selectedAxis: number;
  limitFormControl: Control<LimitFormData, any>;
}
export function FFTChart({ data, labelX, labelY, selectedAxis, limitFormControl }: FFTChartProps) {
  const theme = useTheme();

  const upperLimit = useWatch({control: limitFormControl, name: 'upperLimit'});
  const lowerLimit = useWatch({control: limitFormControl, name: 'lowerLimit'});

  const [upperLimitDatasetOptions, setUpperLimitDatasetOptions] = useState({});
  const [lowerLimitDatasetOptions, setLowerLimitDatasetOptions] = useState({});

  useEffect(() => {
    const xAxisRange: ChartXAxisRange = {
      begin: data.frequencies[0],
      end: data.frequencies[data.frequencies.length - 1],
      axisType: 'number'
    }
    setMultiAxisLimitDataset(xAxisRange, upperLimit, 'upper', selectedAxis, setUpperLimitDatasetOptions);
  }, [upperLimit, selectedAxis]);

  useEffect(() => {
    const xAxisRange: ChartXAxisRange = {
      begin: data.frequencies[0],
      end: data.frequencies[data.frequencies.length - 1],
      axisType: 'number'
    }
    setMultiAxisLimitDataset(xAxisRange, lowerLimit, 'lower', selectedAxis, setLowerLimitDatasetOptions);
  }, [lowerLimit, selectedAxis]);

  return (
    <>
      <div>
        <CanvasJSChart
          options={{
            zoomEnabled: true,
            animationEnabled: true,
            backgroundColor: theme.chart.backgroundColor,
            colorSet: "#FFFFFF",
            theme: "dark2",
            axisX: {
              title: labelX,
              gridThickness: 0,
            },
            axisY: {
              title: labelY,
              gridThickness: 1,
              gridColor: "#4b4d63",
            },
            toolTip: {
              shared: true,
            },
            legend: {
              cursor: "pointer",
              itemclick: function (e: any) {
                if (
                  typeof e.dataSeries.visible === "undefined" ||
                  e.dataSeries.visible
                ) {
                  e.dataSeries.visible = false;
                } else {
                  e.dataSeries.visible = true;
                }

                e.chart.render();
              },
            },
            data: [
              {
                type: "line",
                legendText: "Eixo X",
                showInLegend: true,
                legendMarkerType: "triangle",
                xValueFormatString: '0.00Hz',
                yValueFormatString: CHART_Y_FORMAT,
                dataPoints: data?.xValue?.map((fftValue, index) => {
                  const frequency = data.frequencies[index];
                  return {
                    x: frequency,
                    y: fftValue,
                  };
                }),
              },
              {
                type: "line",
                legendText: "Eixo Y",
                showInLegend: true,
                legendMarkerType: "cross",
                xValueFormatString: '0.00Hz',
                yValueFormatString: CHART_Y_FORMAT,
                dataPoints: data?.yValue?.map((fftValue, index) => {
                  const frequency = data.frequencies[index];
                  return {
                    x: frequency,
                    y: fftValue,
                  };
                }),
              },
              {
                type: "line",
                legendText: "Eixo Z",
                showInLegend: true,
                xValueFormatString: '0.00 Hz',
                yValueFormatString: CHART_Y_FORMAT,
                dataPoints: data?.zValue?.map((fftValue, index) => {
                  const frequency = data.frequencies[index];
                  return {
                    x: frequency,
                    y: fftValue,
                  };
                }),
              },
              upperLimitDatasetOptions,
              lowerLimitDatasetOptions,
            ],
          }}
        />
      </div>
    </>
  );
}
