import { useToast } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";

import { Input } from '../../components/Form/input'

import { useNavigate } from 'react-router-dom';
import { Logo } from '../../components/Header/Logo/Logo';
import { useAuth } from '../../contexts/authContext';
import { Flex } from '../../components/Flex/Flex.styles';
import { useEffect } from 'react';
import { Layout, LoginForm } from './Login.styles';
import { Checkbox } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';
import { PrimaryButton } from '../../components/Buttons/Primary/Primary';


// TODO: remove chakra ui
const signInFormSchema = yup.object().shape({
	email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
	password: yup.string().required('Senha obrigatória')
})

export function Login() {
	const navigate = useNavigate()
	const { signIn, user, loading } = useAuth()

	useEffect(() => {
		if (loading) {
			return;
		}
		if (user != null) {
			navigate('/');
		}
	}, [user]);

	const { register, handleSubmit, formState } = useForm({
		resolver: yupResolver(signInFormSchema)
	})
	const { errors } = formState;

	const toast = useToast();
	const handleSignIn = async ({ email, password }: any) => {
		const authResponse = await signIn(email, password)
		if (authResponse) {
			navigate('/')
		}
		else {
			toast({
				title: 'Autenticação',
				description: "Usuário ou senha inválida",
				status: 'error',
				duration: 5000,
				position: 'top-right',
				isClosable: true,
			})
		}
	}

	return (
		<Layout>
			<LoginForm onSubmit={handleSubmit(handleSignIn)}>

				<Flex className='column' $rowGap='2rem'>
					<Flex className='align-items-center justify-content-center w-100'>
						<Logo />
					</Flex>

					<Input
						type="email"
						error={errors.email}
						label="E-mail"
						{...register('email')}
					/>
					<Input
						type="password"
						label="Senha"
						error={errors.password}
						{...register('password')}
					/>
				</Flex>

				<Checkbox style={{ marginTop: '2rem' }} color='primary' shape="curve" animation='smooth' defaultChecked>
					Permanecer conectado por uma semana
				</Checkbox>

				<PrimaryButton title="Entrar" loading={formState.isSubmitting} attributes={{type:'submit'}}></PrimaryButton>
			</LoginForm>
		</Layout>);
}
