import { Input as ChakraInput, FormLabel, FormControl, InputProps as ChakraInputProps, FormErrorMessage } from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldErrors } from 'react-hook-form'

function InputBase({ name, label, error = null, ...rest }, ref)  {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name} >{label}</FormLabel>}
      <ChakraInput
        name={name}
        id={name}
        focusBorderColor="red.200"
        bgColor="gray.900"
        borderColor="gray.700"
        size="lg"
        _hover={{
          bgColor: 'gray.900'
        }}
        ref={ref}
        {...rest}
      />

      {
        !!error && (
          <FormErrorMessage>
            {error.message}
          </FormErrorMessage>
        )
      }
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)