import styled from "styled-components";
import { ITheme } from "../../../styles/theme";

export const ChartContainer = styled.div`
  padding: 16px 0px;
  padding: 1rem;
  background-color: ${props => (props.theme as ITheme).chart.backgroundColor};
  border-radius: 1rem;
`;

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ChartTitle = styled.span`
	font-weight: 600;
	font-size: 20px;
	color: var(--font-white);
`;

export const ChartButton = styled.button`
	padding: 0.2rem;
	border-radius: 5px;
	font-weight: bold;
	color: white;
`;
