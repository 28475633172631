import { Popover } from "react-tiny-popover";
import { BasePopupContainer } from "../../../../components/Popover/ThemePopover.styles";
import { ThemeIconOnlyFilledButton } from "../../../../components/Buttons/IconOnly/ThemeIconOnly.styles";
import { FaMagnifyingGlassChart } from "react-icons/fa6";
import { Flex } from "../../../../components/Flex/Flex.styles";
import { ThemeFilledButton } from "../../../../components/Buttons/Primary/Primary.styles";
import { BoldText, Footer, MainText, PopoverTitle } from "./ChartOutlier.styles";
import { Checkbox, useCheckboxState } from "pretty-checkbox-react";
import { useCallback, useEffect, useRef, useState } from "react";

export interface LimitFormData {
  upperLimit: number | null;
  lowerLimit: number | null;
  limitAxis?: number;
}
interface IChartOutlierProps {
  isOpen: boolean;
  isOutlierDetectionEnabled: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
  onOutlierConfigSave: (enableOutlierDetection: boolean) => void;
}
export function ChartOutlier(props: IChartOutlierProps) {

  const [checked, setChecked] = useState<string | boolean | any[] | undefined>(false);
  useEffect(() => {
    setChecked(props.isOutlierDetectionEnabled);
  }, [props.isOutlierDetectionEnabled]);
  const onChange = useCallback(() => {
    setChecked(prev => !prev);
  }, []);

  // TODO: Hiding for now
  return (<></>);

  return (
    <Popover isOpen={props.isOpen} positions={['left']}
      padding={10}
      // onClickOutside={(e) => {
      //   console.log("click outside", e);

      //   props.onCloseModal()
      // }}
      content={
        <BasePopupContainer style={{ maxWidth: "400px" }}>
          <Flex className="column">

            <Flex className="justify-content-center" style={{ paddingBottom: '1rem' }}>
              <PopoverTitle>Detecção de outliers</PopoverTitle>
            </Flex>

            <Checkbox state={checked} setState={setChecked} onChange={onChange} color='primary' shape="curve" animation='smooth'>
              Detectar diariamente
            </Checkbox>

            <MainText>
              A detecção irá calcular um envelope de acordo com os dados e notificará o usuário por email caso encontre valores acima ou abaixo dos limites estabelecidos.
            </MainText>

            <BoldText>
              Essa operação irá sobrescrever os limites do indicador selecionado.
            </BoldText>

            <Footer>
              <ThemeFilledButton style={{ width: "90px" }} type="button" onClick={() => props.onOutlierConfigSave(checked as boolean)}>Salvar</ThemeFilledButton>
            </Footer>
          </Flex>
        </BasePopupContainer>
      }>
      <div>
        <ThemeIconOnlyFilledButton as={FaMagnifyingGlassChart} onClick={() => {
          props.isOpen ? props.onCloseModal() : props.onOpenModal();
        }}></ThemeIconOnlyFilledButton>
      </div>
    </Popover>
  );

}
