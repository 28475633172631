import styled from "styled-components";
import { Flex } from "../../../Flex/Flex.styles";
export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  row-gap: 1rem;
`
export const FieldContainer = styled(Flex).attrs(props => ({
  className: 'column custom-scrollbar'
}))`
  overflow-y: auto;
`

export const FieldLabel = styled.span`
  width: 194px;
`
export const ItemList = styled(Flex).attrs(props => ({
  className: 'column custom-scrollbar'
}))`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem 0;
  row-gap: 0.5rem;
`
export const EmptyMessage = styled(Flex).attrs(props => ({
  className: 'justify-content-center'
}))``;
export const ContactItem = styled(Flex)`
  padding: 0px 0.5rem;
  align-items: center;
`

export const GridRow = styled.div<{$isHidden?:boolean}>`
  display: ${props => props.$isHidden ? 'none' : 'grid'};
  grid-template-columns: auto auto auto 32px;
  column-gap: 1rem;
  padding: 0px 0.5rem;
`