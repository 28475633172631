import axios from "axios";
import { CreateSensorDTO } from "../dto/create-sensor.dto";
import { SensorResponseDto } from "../responses/sensor-response.dto";
import { ListSensorsResponseDto } from "../responses/list-sensor-response.dto";
import { SensorDataResponseDTO, SensorTemperatureResponseDTO } from "../responses/sensor-data-response.dto";

class SensorService {
  private readonly baseUrl = process.env.REACT_APP_REST_API_ENDPOINT + '/sensors';

  public getSensorByMacAddress(sensorMacAddress: string) {
    return axios.get<SensorResponseDto>(this.baseUrl + `/sensor/${sensorMacAddress}`);
  }

  public listSensors() {
    return axios.get<ListSensorsResponseDto[]>(this.baseUrl);
  }

  public updateName(sensorId: string, name: string, macAddress: string) {
    const body: CreateSensorDTO = {
      name,
      macAddress,
    };
    return axios.put<SensorResponseDto>(this.baseUrl + `/${sensorId}`, body);
  }

  public getAccelerationData(macAddress: string, accelerationId?: string) {
    const params = { params: { accelerationId } };
    return axios.get<SensorDataResponseDTO>(this.baseUrl + `/dashboard-acceleration-data/${macAddress}`, params)
  }

  /** Gets AccelerationRMS, crestFactor, STD and velocityRMS
   * @param dateRange # of Days before reference date.
   * @param accelerationId If null, the newest sensor data will be queried.
   */
  public getAccelerationScalarData(macAddress: string, dateRange: number, accelerationId?: string) {
    const params = { params: { dateRange, accelerationId } };
    return axios.get<SensorDataResponseDTO>(this.baseUrl + `/dashboard-acceleration-scalar-data/${macAddress}`, params)
  }

  public getTemperatureData(macAddress: string, endDate?: Date, dateRange?: number) {
    const params = { params: { dateRange, to: endDate } };
    return axios.get<SensorTemperatureResponseDTO>(this.baseUrl + `/temperature/${macAddress}`, params)
  }

}
const sensorService = new SensorService();
export default sensorService;