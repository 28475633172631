import { Logo } from "./Logo/Logo";

import { Profile } from "./Profile/Profile";
import { breakpoint } from "../../styles/theme";
import { SidebarButton } from "./Header.styles";
import { Flex } from "../Flex/Flex.styles";
import { AiOutlineMenu } from "react-icons/ai";
import { useMediaQuery } from '@react-hook/media-query'

export function Header(props: { onSideBarIconClick: () => void }) {

	const { onSideBarIconClick } = props;

	const isMobile = useMediaQuery(`(max-width: ${breakpoint.sm})`)
	if (isMobile) {
		return (
			<Flex className="justify-content-between" $padding="1rem 1.2rem">

				<SidebarButton onClick={onSideBarIconClick} style={{ alignSelf: 'flex-end', width: '72px' }}>
					<AiOutlineMenu></AiOutlineMenu>
				</SidebarButton>

				<Logo></Logo>

				<Profile showProfileData={false}></Profile>

			</Flex>
		);
	}

	return (
		<Flex className="justify-content-between" $padding="1rem 1.2rem">

			<Flex $columnGap="0.5rem" className="align-items-center">
				<Logo></Logo>
				<SidebarButton onClick={onSideBarIconClick} style={{ alignSelf: 'flex-end' }}>
					<AiOutlineMenu></AiOutlineMenu>
				</SidebarButton>
			</Flex>

			<Flex>
				<Profile showProfileData={true} />
			</Flex>

		</Flex>
	)
}