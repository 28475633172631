import 'react-tabs/style/react-tabs.css';
import { ActiveCard } from "./EspCard/EspCard";
import { HomeLayout } from "../../components/HomeLayout/HomeLayout";
import { HomeContainer, EspCardTooltip, HomeGrid } from "./Home.styles";
import { useEffect, useState } from "react";
import { ListSensorsResponseDto } from '../../services/responses/list-sensor-response.dto';
import sensorService from '../../services/http/sensor.service';
import Loading from '../../components/Loading/Loading';
import Utils from '../../services/utils';

export function Home() {
  const [loading, setLoading] = useState(true);
  const [espList, setEspList] = useState<ListSensorsResponseDto[]>([]);

  useEffect(() => {
    sensorService.listSensors()
      .then(
        (response) => {
          setLoading(false);
          setEspList(response.data.map(x => ({
            ...x,
            lastDataReceivedAt: Utils.getDateFromString(x.lastDataReceivedAt)
          })));
        }, () => {
          setLoading(false);
        }
      );
  }, []);

  return (
    <HomeLayout>
      <>
        <HomeContainer $isLoading={loading}>
          {loading && <Loading />}

          {!loading &&
            <HomeGrid>
              {espList.map((esp: ListSensorsResponseDto) => (
                <ActiveCard key={esp.id} sensorId={esp.id} macAddress={esp.macAddress} lastDataReceivedAt={esp.lastDataReceivedAt} espName={esp.name} />
              ))}
            </HomeGrid>
          }
        </HomeContainer>
        <EspCardTooltip id="my-tooltip" place="bottom"></EspCardTooltip>
      </>
    </HomeLayout>
  );
}


