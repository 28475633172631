
export enum Axis {
  X, Y, Z
}

export enum ChartTypeEnum {
  temperature = "temperature",
  voltage = "voltage",
}

export enum Indicator {
  ACCELERATION = "acceleration",
  ACCELERATION_FFT = "acceleration-fft",
  VELOCITY = "velocity",
  VELOCITY_FFT = "velocity-fft",
  DISPLACEMENT = "displacement",
  DISPLACEMENT_FFT = "displacement-fft",

  ACCELERATION_RMS = "acceleration-rms",
  CREST_FACTOR = "crest-factor",
  STANDARD_DEVIATION = "standard-deviation",
  VELOCITY_RMS = "velocity-rms",

  TEMPERATURE = "temperature"
}

// TODO: Substituir o antigo por esse. Está de acordo com a tabela.
export enum NewIndicatorEnum {
  ACCELERATION = 1,
  ACCELERATION_FFT,
  VELOCITY,
  VELOCITY_FFT,
  DISPLACEMENT,
  ACCELERATION_RMS,
  CREST_FACTOR,
  STANDARD_DEVIATION,
  VELOCITY_RMS,
  TEMPERATURE,
}
