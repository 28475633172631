import { Tab, TabList } from "react-tabs";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { ChartHeaderContainer, EmptyChartLabel, StyledTabPanel, StyledTabs } from "./ChartArea.styles";
import { SensorDataResponseDTO, SingleAxisResponse } from "../../../services/responses/sensor-data-response.dto";
import { Messages } from "../../../services/messages";
import { Flex } from "../../../components/Flex/Flex.styles";
import { ChartView, LimitValue, LoadingSources } from "../Dashboard.types";
import { useForm } from "react-hook-form";
import { ChartLimit, LimitFormData } from "./ChartLimit/ChartLimit";
import { VelocityChart } from "../../../components/charts/Velocity";
import { FFTChart } from "../../../components/charts/FFTChart";
import { DateChart } from "../../../components/charts/DateChart";
import { SimpleChart } from "../../../components/charts/SimpleChart/SimpleChart";
import texts from "../../../constants/texts";
import Loading from "../../../components/Loading/Loading";
import { Center } from "../../../components/Center/Center.styles";
import { ChartOutlier } from "./ChartOutlier/ChartOutlier";

const emptyChartLabel = Messages["vibration-empty"];

interface IChartAreaProps {
  loadingSources: Pick<LoadingSources, "accelerationData" | "accelerationScalarData" | "temperatureData">,
  accelerationData?: SensorDataResponseDTO,
  accelerationScalarData?: SensorDataResponseDTO
  temperatureData?: SingleAxisResponse[],
  /** Chart list with their limits */
  chartViewList: ChartView[],

  onChartLimitSave: (isMultiLimit: boolean, indicator: string, limitValue: LimitValue, axis?: number) => void,
  temperatureLimit?: LimitValue,

  onOutlierConfigSave: (enableOutlierDetection: boolean, tabIndex: number) => void;
}
export function ChartArea(props: IChartAreaProps) {
  const { loadingSources, accelerationData, accelerationScalarData, chartViewList } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const {
    pages: {
      dashboard: {
        charts: { temperature },
      },
    },
  } = texts;

  // Chart Limit
  const [selectedAxis, setSelectedAxis] = useState(0);
  const { register, setValue, control, getValues } = useForm<LimitFormData>({
    defaultValues: {
      upperLimit: null,
      lowerLimit: null,
      limitAxis: 0
    }
  });

  // Temperature Limit
  const { register: temperatureRegister, getValues: temperatureGetValues, reset: temperatureReset, watch: temperatureWatch } = useForm<LimitFormData>({
    defaultValues: {
      upperLimit: props.temperatureLimit?.maxValue,
      lowerLimit: props.temperatureLimit?.minValue,
    }
  });
  const temperatureUpperLimit = temperatureWatch("upperLimit")
  const temperatureLowerLimit = temperatureWatch("lowerLimit")

  // Limit modal
  const [isLimitModalOpen, setIsLimitModalOpen] = useState<number | null>(null);
  const [isTemperatureLimitModalOpen, setIsTemperatureLimitModalOpen] = useState<number | null>(null);
  const modalProps = useMemo(() => ({
    isOpen: isLimitModalOpen === tabIndex,
    onOpenModal: () => setIsLimitModalOpen(tabIndex),
    onCloseModal: () => setIsLimitModalOpen(null),
  }), [isLimitModalOpen, tabIndex])
  const temperatureModalProps = useMemo(() => ({
    isOpen: isTemperatureLimitModalOpen === tabIndex,
    onOpenModal: () => setIsTemperatureLimitModalOpen(tabIndex),
    onCloseModal: () => setIsTemperatureLimitModalOpen(null),
  }), [isTemperatureLimitModalOpen, tabIndex])

  // Outlier modal
  const [isOutlierModalOpen, setIsOutlierModalOpen] = useState<number | null>(null);
  const outlierModalProps = useMemo(() => ({
    isOpen: isOutlierModalOpen === tabIndex,
    isOutlierDetectionEnabled: chartViewList[tabIndex].outlierDetectionEnabled,
    onOpenModal: () => setIsOutlierModalOpen(tabIndex),
    onCloseModal: () => setIsOutlierModalOpen(null),
    onOutlierConfigSave: (isOutlierDetectionEnabled: boolean) => props.onOutlierConfigSave(isOutlierDetectionEnabled, tabIndex),
  }), [isOutlierModalOpen, tabIndex, chartViewList])

  // Chart limit
  useEffect(() => {
    if (selectedAxis === 0) {
      setValue('upperLimit', chartViewList[tabIndex].upperLimit.x)
      setValue('lowerLimit', chartViewList[tabIndex].lowerLimit.x)
      return;
    }

    if (selectedAxis === 1) {
      setValue('upperLimit', chartViewList[tabIndex].upperLimit.y)
      setValue('lowerLimit', chartViewList[tabIndex].lowerLimit.y)
      return;
    }

    setValue('upperLimit', chartViewList[tabIndex].upperLimit.z)
    setValue('lowerLimit', chartViewList[tabIndex].lowerLimit.z)
  }, [selectedAxis]);

  // Temperature Limit
  useEffect(() => {
    temperatureReset({
      upperLimit: props.temperatureLimit?.maxValue,
      lowerLimit: props.temperatureLimit?.minValue,
    });
  }, [props.temperatureLimit]);

  // Tab Change
  useEffect(() => {
    if (chartViewList.length === 0) {
      return;
    }

    setValue('upperLimit', chartViewList[tabIndex].upperLimit.x)
    setValue('lowerLimit', chartViewList[tabIndex].lowerLimit.x)
    setSelectedAxis(0);

    const selectedTabIsHidden = !(chartViewList[tabIndex].isVisible) || chartViewList[tabIndex].disabled;
    if (selectedTabIsHidden) {
      changeTabForNearestAvailable();
    }
  }, [chartViewList, tabIndex]);

  // Chart limit
  function onAxisChange(newAxis: number) {
    setSelectedAxis(newAxis);
  }
  function onChartLimitSave() {
    const chartUpperLimit = getValues("upperLimit");
    const chartLowerLimit = getValues("lowerLimit");
    props.onChartLimitSave(true, chartViewList[tabIndex].indicator, { minValue: chartLowerLimit, maxValue: chartUpperLimit }, selectedAxis);
  }

  // Temperature limit
  function onTemperatureLimitSave() {
    props.onChartLimitSave(false, 'temperature',
      { minValue: temperatureGetValues('lowerLimit'), maxValue: temperatureGetValues('upperLimit') });
  }

  // Tabs
  function changeTabForNearestAvailable() {
    // Search for visible tab ahead
    for (let index = tabIndex + 1; (index < chartViewList.length); index++) {
      if (chartViewList[index].isVisible && !chartViewList[index].disabled) {
        setTabIndex(index);
        return;
      }
    }

    // Search for visible tab behind
    for (let index = tabIndex - 1; (index >= 0); index--) {
      if (chartViewList[index].isVisible && !chartViewList[index].disabled) {
        setTabIndex(index);
        return;
      }
    }

  }
  function getTabCharts(chartViewList: ChartView[]) {
    return chartViewList.map((chartView, index) => {
      if (!chartView.isVisible || chartView.disabled) {
        return <StyledTabPanel key={index} />;
      }

      return (
        <StyledTabPanel key={index}>
          <EmptyChartLabel>{emptyChartLabel}</EmptyChartLabel>
        </StyledTabPanel>
      );
    });
  }

  function CustomTabPanel(props: PropsWithChildren<{ loading: boolean }>) {
    if (props.loading) {
      return (
        <Center style={{ height: '300px' }}>
          <Loading></Loading>
        </Center>
      )
    }
    return props.children;
  }

  return (
    <>
      <StyledTabs disableUpDownKeys focusTabOnClick={false} selectedIndex={tabIndex} onSelect={(newIndex) => setTabIndex(newIndex)}>
        <Flex className="ai-end" $columnGap="1rem">
          <TabList style={{ marginBottom: '0.5rem' }}>
            {getTabHeader(chartViewList)}
          </TabList>
        </Flex>

        {/* Escalares */}
        <StyledTabPanel>
          <CustomTabPanel loading={loadingSources.accelerationScalarData}>
            <ChartHeaderContainer>
              <ChartOutlier {...outlierModalProps} />

              <ChartLimit
                {...modalProps}
                register={register} selectedAxis={selectedAxis}
                onAxisChange={onAxisChange} onLimitSave={onChartLimitSave}
                isMultiLimit={true}
              />
            </ChartHeaderContainer>
            <DateChart
              labelY="Aceleração RMS (g)"
              labelX="Hórario"
              data={{
                xValue: accelerationScalarData?.accelerationRMS?.value.xAxis ?? [],
                yValue: accelerationScalarData?.accelerationRMS?.value.yAxis ?? [],
                zValue: accelerationScalarData?.accelerationRMS?.value.zAxis ?? [],
                date: accelerationScalarData?.accelerationRMS?.value.dateAxis ?? [],
              }}
              selectedAxis={selectedAxis}
              limitFormControl={control}
            />
          </CustomTabPanel>
        </StyledTabPanel>
        <StyledTabPanel>
          <CustomTabPanel loading={loadingSources.accelerationScalarData}>
            <ChartHeaderContainer>
              <ChartOutlier {...outlierModalProps} />

              <ChartLimit
                {...modalProps}
                register={register} selectedAxis={selectedAxis}
                onAxisChange={onAxisChange} onLimitSave={onChartLimitSave}
                isMultiLimit={true}
              />
            </ChartHeaderContainer>
            <DateChart
              labelY="Fatores de Crista"
              labelX="Hórario"
              data={{
                xValue: accelerationScalarData?.crestFactor?.value.xAxis ?? [],
                yValue: accelerationScalarData?.crestFactor?.value.yAxis ?? [],
                zValue: accelerationScalarData?.crestFactor?.value.zAxis ?? [],
                date: accelerationScalarData?.crestFactor?.value.dateAxis ?? [],
              }}
              selectedAxis={selectedAxis}
              limitFormControl={control}
            />
          </CustomTabPanel>

        </StyledTabPanel>
        <StyledTabPanel>
          <CustomTabPanel loading={loadingSources.accelerationScalarData}>
            <ChartHeaderContainer>
              <ChartOutlier {...outlierModalProps} />

              <ChartLimit
                {...modalProps}
                register={register} selectedAxis={selectedAxis} onAxisChange={onAxisChange} onLimitSave={onChartLimitSave}
                isMultiLimit={true}
              />
            </ChartHeaderContainer>
            <DateChart
              labelY="Desvio Padrão (g)"
              labelX="Hórario"
              data={{
                xValue: accelerationScalarData?.stdDeviation?.value.xAxis ?? [],
                yValue: accelerationScalarData?.stdDeviation?.value.yAxis ?? [],
                zValue: accelerationScalarData?.stdDeviation?.value.zAxis ?? [],
                date: accelerationScalarData?.stdDeviation?.value.dateAxis ?? [],
              }}
              selectedAxis={selectedAxis}
              limitFormControl={control}
            />
          </CustomTabPanel>

        </StyledTabPanel>
        <StyledTabPanel>
          <CustomTabPanel loading={loadingSources.accelerationScalarData}>
            <ChartHeaderContainer>
              <ChartOutlier {...outlierModalProps} />
              <ChartLimit
                {...modalProps} register={register} selectedAxis={selectedAxis} onAxisChange={onAxisChange} onLimitSave={onChartLimitSave}
                isMultiLimit={true}
              />
            </ChartHeaderContainer>
            <DateChart
              labelY="Velocidade RMS (mm/s)"
              labelX="Hórario"
              data={{
                xValue: accelerationScalarData?.velocityRMS?.value.xAxis ?? [],
                yValue: accelerationScalarData?.velocityRMS?.value.yAxis ?? [],
                zValue: accelerationScalarData?.velocityRMS?.value.zAxis ?? [],
                date: accelerationScalarData?.velocityRMS?.value.dateAxis ?? [],
              }}
              selectedAxis={selectedAxis}
              limitFormControl={control}
            />
          </CustomTabPanel>
        </StyledTabPanel>

        {/* Acceleration */}
        <StyledTabPanel>
          <CustomTabPanel loading={loadingSources.accelerationData}>
            <ChartHeaderContainer>
              <ChartOutlier {...outlierModalProps} />
              <ChartLimit  {...modalProps} register={register} selectedAxis={selectedAxis} onAxisChange={onAxisChange} onLimitSave={onChartLimitSave}
                isMultiLimit={true}
              />
            </ChartHeaderContainer>
            <VelocityChart
              labelX="Tempo (s)"
              labelY="Aceleração (g)"
              data={{
                xValue: accelerationData?.acceleration?.value.xAxis ?? [],
                yValue: accelerationData?.acceleration?.value.yAxis ?? [],
                zValue: accelerationData?.acceleration?.value.zAxis ?? [],
              }}
              measurement="g"
              selectedAxis={selectedAxis}
              limitFormControl={control}
            />
          </CustomTabPanel>
        </StyledTabPanel>
        <StyledTabPanel>
          <CustomTabPanel loading={loadingSources.accelerationData}>
            <FFTChart
              labelX="Frequência (Hz)"
              labelY="Aceleração (g)"
              data={{
                xValue: accelerationData?.accelerationFFT?.value.xAxis ?? [],
                yValue: accelerationData?.accelerationFFT?.value.yAxis ?? [],
                zValue: accelerationData?.accelerationFFT?.value.zAxis ?? [],
                frequencies: accelerationData?.accelerationFFT?.value.frequencyAxis ?? []
              }}
              selectedAxis={selectedAxis}
              limitFormControl={control}
            />
          </CustomTabPanel>
        </StyledTabPanel>
        {/* Velocity */}
        <StyledTabPanel>
          <CustomTabPanel loading={loadingSources.accelerationData}>
            <ChartHeaderContainer>
              <ChartOutlier {...outlierModalProps} />
              <ChartLimit  {...modalProps} register={register} selectedAxis={selectedAxis} onAxisChange={onAxisChange} onLimitSave={onChartLimitSave}
                isMultiLimit={true}
              />
            </ChartHeaderContainer>
            <VelocityChart
              labelX="Tempo (s)"
              labelY="Velocidade (mm/s)"
              data={{
                xValue: accelerationData?.velocity?.value.xAxis ?? [],
                yValue: accelerationData?.velocity?.value.yAxis ?? [],
                zValue: accelerationData?.velocity?.value.zAxis ?? [],
              }}
              measurement="mm/s"
              selectedAxis={selectedAxis}
              limitFormControl={control}
            />
          </CustomTabPanel>
        </StyledTabPanel>
        <StyledTabPanel>
          <CustomTabPanel loading={loadingSources.accelerationData}>
            <ChartHeaderContainer>
            </ChartHeaderContainer>
            <FFTChart
              labelX="Frequência (Hz)"
              labelY="Velocidade (mm/s)"
              data={{
                xValue: accelerationData?.velocityFFT?.value.xAxis ?? [],
                yValue: accelerationData?.velocityFFT?.value.yAxis ?? [],
                zValue: accelerationData?.velocityFFT?.value.zAxis ?? [],
                frequencies: accelerationData?.velocityFFT?.value.frequencyAxis ?? []
              }}
              selectedAxis={selectedAxis}
              limitFormControl={control}
            />
          </CustomTabPanel>

        </StyledTabPanel>
        {/* Deslocamento */}
        <StyledTabPanel>
          <CustomTabPanel loading={loadingSources.accelerationData}>
            <ChartHeaderContainer>
              <ChartOutlier {...outlierModalProps} />
              <ChartLimit {...modalProps} register={register} selectedAxis={selectedAxis} onAxisChange={onAxisChange} onLimitSave={onChartLimitSave}
                isMultiLimit={true}
              />
            </ChartHeaderContainer>
            <VelocityChart
              labelX="Tempo (s)"
              labelY="Deslocamento (mm)"
              data={{
                xValue: accelerationData?.displacement?.value.xAxis ?? [],
                yValue: accelerationData?.displacement?.value.yAxis ?? [],
                zValue: accelerationData?.displacement?.value.zAxis ?? [],
              }}
              measurement="mm"
              selectedAxis={selectedAxis}
              limitFormControl={control}
            />
          </CustomTabPanel>
        </StyledTabPanel>

      </StyledTabs>

      <SimpleChart
        modalProps={temperatureModalProps}
        isLoading={props.loadingSources.temperatureData}
        data={props.temperatureData ?? []}
        label={temperature.label}
        title={temperature.title}

        showLimitForm={true}
        register={temperatureRegister}
        onLimitSave={onTemperatureLimitSave}
        upperLimit={temperatureUpperLimit}
        lowerLimit={temperatureLowerLimit}
      />
    </>
  );
}

function getTabHeader(chartViewList: ChartView[]) {
  return chartViewList.map((chartView, index) => (
    <Tab key={index} hidden={!chartView.isVisible || chartView.disabled}>
      {chartView.name}
    </Tab>
  ));
}

