import { BsChevronDown } from 'react-icons/bs'
import { AiOutlineLogout } from 'react-icons/ai'
import { useAuth } from '../../../contexts/authContext'
import { useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { Email, OptionsContainer, Title, UsernameAvatar } from './Profile.styles';
import { Flex } from '../../Flex/Flex.styles';
import { IoIosContact } from 'react-icons/io';
import ContactListModal from './Contacts/ContactListModal';

interface ProfileProps {
  showProfileData?: boolean;
}
export function Profile({ showProfileData = true }: ProfileProps) {
  const { logout, user } = useAuth();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const [usernameLabels, setUsernameLabels] = useState("");
  useEffect(() => {
    if (user?.name == null || user?.name == "") {
      return;
    }

    const username = user?.name.trim();
    const words = username.split(" ");
    const firstLetters = words.map(
      x => x[0]
    );
    let labels = firstLetters[0];
    if (firstLetters.length > 1) {
      labels += firstLetters[1];
    }
    setUsernameLabels(labels);
  }, [user]);

  function ToggleModal() {
    setIsOptionsOpen(!isOptionsOpen);
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  function openContactList() {
    setIsModalOpen(true);
  }

  return (
    <>
      <Popover isOpen={isOptionsOpen} positions={['bottom']}
        align='end' padding={10}
        onClickOutside={() => setIsOptionsOpen(false)}
        content={
          <ProfileOptions openContactListFn={openContactList} logoutFn={logout}></ProfileOptions>
        }>
        <Flex $columnGap='1rem' onClick={ToggleModal} style={{ cursor: 'pointer' }}>
          {showProfileData && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Title>{user?.name}</Title>
              <Email>
                {user?.email}
              </Email>
            </div>
          )}
          <Flex className='align-items-center' $columnGap='0.5rem'>
            <UsernameAvatar>{usernameLabels}</UsernameAvatar>
            <BsChevronDown></BsChevronDown>
          </Flex>
        </Flex>
      </Popover>
      <ContactListModal isModalOpen={isModalOpen} onModalClose={() => setIsModalOpen(false)}></ContactListModal>
    </>
  );

}

interface IProfileOptionsProps {
  openContactListFn: () => void,
  logoutFn: () => void,
}
function ProfileOptions(props: IProfileOptionsProps) {
  const { openContactListFn, logoutFn } = props;
  return (
    <Flex className='column'>
      <OptionsContainer onClick={openContactListFn}>
        <IoIosContact size={'1.2rem'} />
        <span>Contatos</span>
      </OptionsContainer>
      <OptionsContainer onClick={logoutFn}>
        <AiOutlineLogout size={'1.2rem'}></AiOutlineLogout>
        <span>Deslogar</span>
      </OptionsContainer>
    </Flex>
  );
}

