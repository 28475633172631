import { Popover } from "react-tiny-popover";
import "./ChartFilter.styles";
import { useState } from "react";
import { FiFilter } from "react-icons/fi";
import { ChartViewLabel, FilterButton } from "./ChartFilter.styles";
import texts from "../../../../constants/texts";
import { ChartView } from "../../Dashboard.types";
import { BasePopupContainer } from "../../../../components/Popover/ThemePopover.styles";

interface IChartFilterProps {
  chartViewList: ChartView[];
  onCheckBoxSelected: (chartViewList: ChartView[]) => void;
  isDisabled?: boolean;
}

export function ChartFilter(props: IChartFilterProps) {
  const { chartViewList, isDisabled = false } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    pages: {
      dashboard: { filter },
    },
  } = texts;

  function ToggleModal() {
    if(isDisabled){
      return;
    }
    
    setIsModalOpen(!isModalOpen);
  }

  function onCheckBoxSelected(event: any) {
    const index: string = event.target.id;
    const newValue: boolean = event.target.checked;

    let selectedCharts = 0;
    for (const item of chartViewList) {
      if (item.isVisible) {
        selectedCharts++;
      }
      if (selectedCharts >= 2) {
        break;
      }
    }

    const onlyOneChartSelected = selectedCharts === 1;
    if (!newValue && onlyOneChartSelected) {
      return;
    }

    const newList = [...chartViewList];
    newList[parseInt(index)].isVisible = newValue;

    props.onCheckBoxSelected([...newList]);
  }

  return (
    <Popover
      isOpen={isModalOpen}
      positions={["bottom"]}
      align="start"
      padding={10}
      onClickOutside={() => setIsModalOpen(false)}
      content={
        <BasePopupContainer>
          {chartViewList.map((item, index) => {
            return (
              <div key={index}>
                <input
                  id={index.toString()}
                  disabled={item.disabled}
                  checked={item.isVisible}
                  type="checkbox"
                  onChange={onCheckBoxSelected}
                />
                <ChartViewLabel>{item.name}</ChartViewLabel>
              </div>
            );
          })}
        </BasePopupContainer>
      }
    >
      <FilterButton $isDisabled={isDisabled} onClick={ToggleModal}>
        <FiFilter style={{ minWidth: "1rem" }}></FiFilter>
        <span>{filter.btnFilter}</span>
      </FilterButton>
    </Popover>
  );
}
