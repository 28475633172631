import styled from "styled-components";

export const Row = styled.div`
	display: flex;
	align-items: center;

	&.justify-content-between{
		justify-content: space-between;
	}

	&.date-icon{
		column-gap: 0.5rem;
	}
`;

export const DatePickerTitle = styled.span`
  font-size: 1rem;
  font-weight: bold;
  // color: var(--font-color-3);
  color: ${({ theme }) => theme.datePicker.color};	
`

export const DatePickerNavigationLeft = styled.span`
  cursor: pointer;
  width: 32px;
  height: 32px;
  &:hover *::before{
    border-color: ${({ theme }) => theme.datePicker.hoverColor};
  }
`

export const DatePickerNavigationRight = styled.div<{ $isVisible: boolean }>`
  visibility: ${({ $isVisible }) => $isVisible ? 'visible' : 'hidden'};
  cursor: pointer;
  width: 32px;
  height: 32px;
  &:hover *::before{
    border-color: ${({ theme }) => theme.datePicker.hoverColor};
  }
`

export const LoadingLabel = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.datePicker.loadingLabelColor};
  padding-bottom: 0.5rem;
  font-weight: bold;
`

