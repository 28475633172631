import { Tab, TabPanel, Tabs } from "react-tabs";
import styled from "styled-components";
import { ITheme } from "../../../styles/theme";

export const LoadingIconContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '400px'
});


export const StyledTabs = styled(Tabs)`
	padding-bottom: 1rem;
	.react-tabs__tab--selected {
		background: var(--red-1);
		color: white;
		border: none;
		font-weight: 600;
	}
  .react-tabs__tab-list{
    margin: 0px;
  }

`;

export const StyledTab = styled(Tab)`
  // &.react-tabs__tab:focus:after {
  //   display:none;
  // }
`

export const StyledTabPanel = styled(TabPanel)`
  &.react-tabs__tab-panel--selected {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: ${props => (props.theme as ITheme).chart.backgroundColor};
    border-radius: 1rem;
  }

`;

export const ChartHeaderContainer = styled.div`
  display: flex;  
  justify-content: flex-end;
  column-gap: 1rem;
  padding: 1rem;
  padding-top: 0px;
`

export const ChartContainer = styled.div`
  background-color: ${props => props.theme.chart.backgroundColor};
`

export const EmptyChartLabel = styled.div`
	padding-bottom:1rem;
`;

export const Label = styled.span`
  color: #DBDCDF;
  font-size: 1.125rem;
  font-weight: 600;
`;

export const ChartLimitFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
`
