import { Popover } from 'react-tiny-popover';
import { Row, TimePickerInline, TimePopupContent, TimeContainer } from './TimePicker.styles';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { FaAngleDown, FaRegCalendarAlt } from 'react-icons/fa';
import { AccelerationDateResponse } from '../../services/responses/acceleration-date-response.dto';

interface ITimePickerProps {
  accelerationTimes: AccelerationDateResponse[];
  selectedDate?: Date;
  onTimeChange: (accelerationDate: AccelerationDateResponse) => void;
  isDisabled?: boolean;
}
export default function TimePicker(props: ITimePickerProps) {
  const { accelerationTimes: accelerationTimes, selectedDate, isDisabled = false } = props;

  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
  function toggleTimeModal() {
    if (isDisabled) {
      return;
    }

    setIsTimeModalOpen(!isTimeModalOpen);
  }

  function onTimeChange(accelerationDate: AccelerationDateResponse) {
    props.onTimeChange(accelerationDate);
  }

  return (
    <Popover isOpen={isTimeModalOpen} positions={['bottom']}
      align="start" padding={10}
      onClickOutside={() => setIsTimeModalOpen(false)}
      content={
        <TimeModalContent accelerationTimes={accelerationTimes} selectedDate={selectedDate} onTimeChange={onTimeChange}></TimeModalContent>
      }>
      <TimePickerInline $isDisabled={isDisabled} onClick={toggleTimeModal}>
        <Row className='justify-content-between'>
          <Row className="date-icon">
            <FaRegCalendarAlt color="var(--gray-1)" />
            <span>{selectedDate == null ? 'Horário' : format(selectedDate, "HH:mm")}</span>
          </Row>
          <FaAngleDown></FaAngleDown>
        </Row>
      </TimePickerInline>
    </Popover>
  );
}

interface ITimeModalContentProps extends ITimePickerProps {
}
function TimeModalContent(props: ITimeModalContentProps) {
  const { accelerationTimes: accelerationDates, selectedDate, onTimeChange } = props;
  return (
    <TimePopupContent className='hide-scrollbar'>
      {accelerationDates.map((x, index) =>
        <TimeContainer $selected={x.date == selectedDate} key={index}
          onClick={() => onTimeChange(x)}>
          {format(x.date, 'HH:mm')}
        </TimeContainer>
      )}
    </TimePopupContent>
  );
}