import styled from "styled-components";
import { breakpoint } from "../../../../styles/theme";

export const FilterButton = styled.button<{$isDisabled?: boolean}>`
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
	color: var(--gray-2);
	
	@media (max-width: ${breakpoint.sm}) {
		text-align: start;
    padding-left: 0.8rem;
	}

  cursor: ${props => props.$isDisabled ? 'default' : 'pointer'};

`;

export const ChartViewLabel = styled.span`
	margin-left: 0.5rem;
`;
