import axios from "axios";
import { UserLoginResponse } from "../responses/user-login-response.dto";

class AuthService {
  private readonly baseUrl = process.env.REACT_APP_REST_API_ENDPOINT + '/auth';

  public signIn(username:string, password:string) {
    const body = {
      email: username,
      password
    };
    return axios.post<UserLoginResponse>(this.baseUrl + '/login', body);
  }

}
const authService = new AuthService();
export default authService;