import styled from "styled-components";

export const EspCardContainer = styled.div<{ $isEditing?: boolean }>`
	background-color: var(--gray-4);
	border-radius: 12px;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 0.5rem;
	cursor: ${props => props.$isEditing ? 'default' : 'pointer'};
`;

export const EspTag = styled.span<{ $isOnline?: boolean }>`
	background-color: var(--gray-4);

	color: ${props => props.$isOnline ? 'var(--green-1)' : 'var(--red-1)'};
	font-weight: 600;

	>span{
	padding: 0.2rem 0.4rem;

		border-radius: 8px;
		border: 1px solid ${props => props.$isOnline ? 'var(--green-1)' : 'var(--red-1)'};
	}
`;

export const EspNameContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.25rem;
	font-weight: 600;
	column-gap: 1rem;
`;

export const EspNameInput = styled.input`
	width: 100%;	
	font-size: 1.25rem;
	font-weight: 600;
	border-bottom: 1px solid white;
	color: white;
	background-color: var(--gray-4);
	padding: 0rem 0.5rem;

	&:focus-visible{
		outline: none;
	}

	&:hover{
		background-color: var(--gray-4);
	}

	&:focus{
		border-color: var(--red-1);
	}
	
`;

export const EspNameLabel = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow-x: hidden;
	border-bottom: 1px solid transparent;
`;


