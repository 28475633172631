import { extendTheme } from '@chakra-ui/react'

// Obsolete, contribute to the new theme
export const chakraTheme = extendTheme({
  colors: {
    gray: {
      "950": "#1A1A1A",
      "900": "#181b23",
      "800": "#1f2029",
      "850": "#282a36",
      "700": "#353646",
      "600": "#4b4d63",
      "500": "#636480",
      "400": "#797d9a",
      "300": "#9699b0",
      "200": "#b3b5c6",
      "150": "#9F9F9F",
      "100": "#d1d2dc",
      "50": "#eeeef2",
      "40": "#f7f7f7"
    },
    red: {
      "200": "#DB6262"
    },
    yellow: {
      "900": "#FFCE00"
    },
    white: {
      "900": "#ffffff",
    },
    blue: {
	  "200:" : "#279CE6",	
      "700": "#3253EE",
      "600": "#625BF6"
    }
  },
  fonts: {
    heading: 'gilroy',
    body: 'gilroy'
  },
  styles: {
    global: {
      body: {
        bg: 'gray.900',
        color: 'gray.50'
      }
    }
  }
})

