import Texts from "../interfaces/texts.types";
import { Indicator } from "./Chart.enum";

const texts: Texts = {
  pages: {
    login: {
      labelEmail: "E-mail",
      labelPassword: "Senha",
      textCheckbox: "Permanecer conectado por uma semana",
      btnLogin: "Entrar",
      toastMsg: {
        title: "Autenticação",
        errorMsg: "Usuário ou senha inválida",
      },
    },

    home: {
      sidebar: {
        menu: {
          title: "MENU",
          sensorsTab: "Sensores ativos",
        },
      },
      espCard: {
        tooltip: {
          noData: "Nenhum dado recebido",
          lastData: " Último dado recebido em {0}",
        },
        status: {
          online: "Online",
          offline: "Offline",
        },
      },
    },

    dashboard: {
      filter: {
        title: "Escolha o dia e horário que deseja visualizar:",
        btnFilter: "Filtrar gráficos",
        datePicker:{
          loadingLabel: "Carregando datas.."
        }
      },
      charts: {
        tabs: {
          [Indicator.ACCELERATION]: "Aceleração",
          [Indicator.ACCELERATION_FFT]: "Aceleração FFT",
          [Indicator.VELOCITY]: "Velocidade",
          [Indicator.VELOCITY_FFT]: "Velocidade FFT",
          [Indicator.DISPLACEMENT]: "Deslocamento",
          [Indicator.ACCELERATION_RMS]: "Aceleração RMS",
          [Indicator.CREST_FACTOR]: "Fator de Crista",
          [Indicator.STANDARD_DEVIATION]: "Desvio Padrão",
          [Indicator.VELOCITY_RMS]: "Velocidade RMS",
        },
        acceleration: {
          title: "Análise de vibração do dia {0}",
          emptyChartTxt:
            "Ainda não há dados de vibração para este equipamento ou não há gráficos selecionados",
          labels: {
            frequency: "Frequência (Hz)",
            acceleration: "Aceleração (g)",
            time: "Horário",
            timeSeconds: "Time (s)",
            velocity: "Velocidade (mm/s)",
            displacement: "Deslocamento (mm)",
            accelerationRMS: "Aceleração RMS (g)",
            crestFactor: "Fatores de Crista",
            stdDeviation: "Desvio Padrão (g)",
            velocityRMS: "Velocidade RMS (mm/s)",
          },
        },
        temperature: {
          title: "Temperatura",
          label: "Temperatura (°C)",
        },
        voltage: {
          title: "Tensão",
          label: "Tensão (V)",
        },
      },
    },
  },
};

export default texts;
