import styled from "styled-components";
import { breakpoint } from "../../styles/theme";

export const FlexColumn = styled.div({
	display: 'flex',
	flexDirection: 'column',
	height: '100%'
})

export const Flex = styled.div`
	display: flex;
	flex-grow: 1;
	padding: 1rem 1.5rem 0;
	@media (max-width: ${breakpoint.sm}){
		padding: 0 0.5rem 1rem;
	}
	overflow-y: auto;
`;

export const Content = styled.div.attrs(props => ({
  className: "custom-scrollbar"
}))`
	flex-grow: 1;
	overflow-y: auto;
	margin-bottom: 0.5rem;
`;





