import { EmptyMessage, ItemList, ContactForm, FieldLabel, FieldContainer, GridRow } from "./ContactListModal.styles";
import { ThemeInput } from "../../../Form/Input/ThemeInput.styles";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ThemeIconOnlyOutlinedButton } from "../../../Buttons/IconOnly/ThemeIconOnly.styles";
import ThemeModal from "../../../Modal/ThemeModal";
import { IContactListModalProps, IContactForm, IContact } from "./ContactListModal.types";
import { ThemeFilledButton } from "../../../Buttons/Primary/Primary.styles";
import { EMAIL_PATTERN } from "../../../../services/utils";
import { Messages } from "../../../../services/messages";
import contactService from "../../../../services/http/contact.service";
import { CreateContactDTO } from "../../../../services/dto/create-contact.dto";
import { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import InputMask from 'react-input-mask';
import Loading from "../../../Loading/Loading";
import { Center } from "../../../Center/Center.styles";

export default function ContactListModal(props: IContactListModalProps) {

  return (
    <ThemeModal isOpen={props.isModalOpen} title="Lista de contatos" onModalClose={props.onModalClose}>
      <ContactList></ContactList>
    </ThemeModal>
  );
}

function ContactList() {

  const { control, register, formState, handleSubmit, reset } = useForm<IContactForm>({
    mode: 'onSubmit',
    defaultValues: {
      contactList: []
    }
  });
  const { fields: contactList, append, remove } = useFieldArray({
    control,
    name: "contactList", //Has to be the same as object field name
  });
  const contactListIsEmpty = contactList.length === 0;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    contactService.list()
      .then(response => {
        reset({
          contactList: response.data
        })
      })
      .finally(() => setIsLoading(false));
  }, []);

  function onAddRow() {
    const emptyFormItem = {
      name: "",
      email: "",
      phone: "",
    };
    append(emptyFormItem);
  }

  const onSubmit = async (data: IContactForm) => {
    const request: CreateContactDTO[] = data.contactList.map(x => {
      return {
        email: x.email,
        name: x.name,
        phone: x.phone
      };
    })
    try {
      await contactService.save(request);
      toast.success(Messages["contact-list-success"], {
        position: "top-right"
      });
    } catch (error: any) {
      let errorMessage = "";
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.message;
      }
      else {
        errorMessage = error;
      }

      toast.error(errorMessage, {
        position: "top-right"
      });

    }


  };

  if (isLoading) {
    return (<Center><Loading /></Center>);
  }

  return (
    <ContactForm onSubmit={handleSubmit(onSubmit)}>

      <FieldContainer>
        <GridRow $isHidden={contactListIsEmpty}>
          <FieldLabel>Nome</FieldLabel>
          <FieldLabel>E-mail</FieldLabel>
          <FieldLabel>Telefone</FieldLabel>
        </GridRow>

        {ContactListContent(contactListIsEmpty)}
      </FieldContainer>

      <ThemeIconOnlyOutlinedButton style={{ width: "220px", flexShrink: 0, alignSelf: 'center' }} as={FaPlus} onClick={onAddRow}></ThemeIconOnlyOutlinedButton>
      <ThemeFilledButton style={{ width: '220px', alignSelf: 'center' }}>Salvar</ThemeFilledButton>
      <Toaster />
    </ContactForm>
  );

  function ContactListContent(contactListEmpty: boolean) {
    if (contactListEmpty) {
      return <EmptyMessage>{Messages["contact-list-empty"]}</EmptyMessage>;
    }

    return (
      <ItemList id="item-list">
        {contactList.map((item, index) => (
          <GridRow key={`flex-${item.id}`}>

            <ThemeInput
              {...register(`contactList.${index}.name`,
                {
                  required: true,
                }
              )}
              $hasError={!fieldHasError(index, 'name')}
            />
            <ThemeInput
              {...register(`contactList.${index}.email`,
                {
                  required: true,
                  pattern: EMAIL_PATTERN,
                }
              )}
              $hasError={!fieldHasError(index, 'email')}
            />
            <Controller name={`contactList.${index}.phone`} control={control} render={({ field }) => (
              <ThemeInput as={InputMask} mask="(99)9999 9999" maskChar=" " alwaysShowMask={true}
                $hasError={!fieldHasError(index, 'phone')} {...field}></ThemeInput>
            )}>
            </Controller>
            <ThemeIconOnlyOutlinedButton key={`button-${item.id}`} as={FaMinus} onClick={() => removeRow(index)}></ThemeIconOnlyOutlinedButton>
          </GridRow>
        ))}
      </ItemList>
    );
  }

  function fieldHasError(index: number, field: keyof IContact) {
    if (formState.errors.contactList == null || formState.errors.contactList[index] == null) {
      return true;
    }
    if (formState.errors.contactList[index]![field] != null) {
      return false;
    }

    return true;
  }

  function removeRow(idToRemove: number) {
    remove(idToRemove);
  }
}


