import { Tooltip } from "react-tooltip";
import styled from "styled-components";

export const HomeContainer = styled.div<{$isLoading: boolean}>`
	flex-grow: 1;
	background-color: var(--background-black-2);
	border-radius: 12px;

	@media (max-width: 500px) {
		padding: 1rem;

		${props => {
			if(props.$isLoading){
				return `
				height: 50%;
				display: flex;
				justify-content: center;
				align-items: center;	
				`;
			}
		}}
	}
	@media (min-width: 500px) {
		padding: 2rem;
		${props => {
			if(props.$isLoading){
				return `
				height: 50%;
				display: flex;
				justify-content: center;
				align-items: center;	
				`;
			}
		}}
	}
	
`;

export const HomeGrid = styled.div`
display: grid;
grid-gap: 1.5rem;
grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
flex: 1;
border-radius: 8px;
`;

export const EspCardTooltip = styled(Tooltip)`
	background-color: var(--gray-4) !important;
`;

