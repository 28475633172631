import axios from "axios";
import { CreateContactDTO } from "../dto/create-contact.dto";
import { IContact } from "../../components/Header/Profile/Contacts/ContactListModal.types";

class ContactService {
  private readonly baseUrl = process.env.REACT_APP_REST_API_ENDPOINT + '/notifications';

  public save(contacts: CreateContactDTO[]) {
    const body = contacts;
    return axios.post<any>(this.baseUrl + '/contacts', body);
  }

  public list() {
    return axios.get<IContact[]>(this.baseUrl + '/contacts');
  }

}
const contactService = new ContactService();
export default contactService;