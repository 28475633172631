import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import { useEffect } from "react";
import { Flex } from "../components/Flex/Flex.styles";
import Utils from "./utils";
import Loading from "../components/Loading/Loading";

export function HttpInterceptor(props: any): JSX.Element {

  const { getLoggedUser, logout, user } = useAuth();
  const toast = useToast();
  const location = useLocation();

  function SetHttpRequestInterceptors() {
    axios.interceptors.request.use(
      (request) => {
        const headers = request.headers ?? {};

        // Get authentication token
        const accessToken = Utils.getCookie('sensar@token');
        if (accessToken) {
          headers['Authorization'] = 'Bearer ' + accessToken;
        }

        request.headers = headers;

        return request;
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  }

  function SetHttpResponseInterceptors() {
    const allowAnonymousRoutes = ['/login'];
    const currentRouteNeedsAuth = !allowAnonymousRoutes.some(x => x === location.pathname);

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // error type is not well defined in axios documentation. It seems similar to response.
        // TODO: http status enum
        if (currentRouteNeedsAuth && error.response?.status === 401) {
          toast({
            title: 'Autenticação',
            description: "Usuário não autenticado",
            status: 'error',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          })
          logout();
        }

        if(error.message === 'Network Error'){
          toast({
            title: 'Erro de conexão',
            description: "Não foi possível conectar ao servidor",
            status: 'error',
            duration: 5000,
            position: 'top-right',
            isClosable: true,
          })
          logout();
        }
        
        return Promise.reject(error);
      }
    );
  }

  useEffect(() => {
    SetHttpRequestInterceptors();
    SetHttpResponseInterceptors();
    getLoggedUser();
  }, []);

  useEffect(() => {
    if(user === null){
      logout();
    }
  }, [user]);

  if ((axios.interceptors.request as any).handlers.length === 0) {
    console.log('Loading interceptors');
    return (
      <Flex className="justify-content-center align-items-center h-100">
        <Loading/>
      </Flex>
    );
  }

  return (props.children as JSX.Element);
}





