import styled from "styled-components";

// Refactor to theme
export const StyledButton = styled.button.attrs(props => ({
  ...props
})) <{ $loading: boolean }>`

	display: flex;
	justify-content: center;
	align-items: center;

	margin-top: 2rem;
	background-color: var(--red-1);
	border-radius: 5px;
	font-size: 1.125rem;
	font-weight: 600;
	padding: 0.75rem 1rem;
	color: var(--font-white);

	opacity: ${props => props.$loading ? '0.8' : '1'};

	&:active{
		opacity: 0.8;
	}

	&:focus{
		box-shadow: 0.8;
	}

`;

export const ThemeLinkButton = styled.button`
  cursor: pointer;
  color: ${props => props.theme.button.linkButton.color};

  border:  0px;

  &:hover{
    border-color: ${props => props.theme.button.borderColorHovered};
  }

  &:focus{
    border-color: ${props => props.theme.button.borderColorFocused};
  }

  &:active{
    border-color: ${props => props.theme.button.borderColorFocused};
  }
`;

export const ThemeFilledButton = styled.button`
  cursor: pointer;
  color: ${props => props.theme.button.color};
  font-weight: 600;
  border-radius: ${props => props.theme.button.borderRadius};
  padding: ${props => props.theme.button.padding};

  background-color: ${props => props.theme.button.backgroundColor};
  border:  ${props => props.theme.button.border};

  &:hover{
    border-color: ${props => props.theme.button.borderColorHovered};
    background-color: ${props => props.theme.button.backgroundColorHovered};
  }

  &:active{
    border-color: ${props => props.theme.button.borderColorFocused};
    background-color: ${props => props.theme.button.backgroundColorActive};
  }
 
`;

export const ThemeFilledSecondaryButton = styled.button`
  cursor: pointer;
  color: ${props => props.theme.button.secondaryButton.color};
  padding: ${props => props.theme.button.padding};
  font-weight: 600;
  border-radius: ${props => props.theme.button.borderRadius};

  background-color: ${props => props.theme.button.secondaryButton.backgroundColor};
  border:  ${props => props.theme.button.secondaryButton.border};

  &:hover{
    border-color: ${props => props.theme.button.secondaryButton.backgroundColorHovered};
    background-color: ${props => props.theme.button.secondaryButton.backgroundColorHovered};
  }

  &:active{
    border-color: ${props => props.theme.button.secondaryButton.backgroundColorActive};
    background-color: ${props => props.theme.button.secondaryButton.backgroundColorActive};
  }
 
`;

export const ThemeSelectButton = styled.button<{ $selected?: boolean, $width?: string }>`
  cursor: pointer;
  padding: ${props => props.theme.button.padding};
  border-radius: ${props => props.theme.button.borderRadius};

  width: ${[props => props.$width ? props.$width : ""]};

  color: ${props => props.$selected ?
    props.theme.button.color :
    props.theme.button.secondaryButton.color};
  background-color: ${props => props.$selected ?
    props.theme.button.backgroundColor :
    props.theme.button.secondaryButton.backgroundColor};
  border:  ${props => props.$selected ? props.theme.button.backgroundColor : props.theme.button.outlinedButton.border};

  &:hover{
    color: ${props => props.$selected ?
    props.theme.button.color :
    props.theme.button.secondaryButton.color};
    border-color: ${props => props.$selected ?
    props.theme.button.borderColorHovered :
    props.theme.button.secondaryButton.borderColorHovered};
    background-color: ${props => props.$selected ?
    props.theme.button.backgroundColorHovered :
    props.theme.button.secondaryButton.backgroundColorHovered};
  }

  &:active{
    color: ${props => props.theme.button.color};
    border-color: ${props => props.theme.button.borderColorFocused};
    background-color: ${props => props.theme.button.backgroundColorActive};
  }

`;