import styled from "styled-components";
import { breakpoint } from "../../styles/theme";
import { AiOutlineClose } from "react-icons/ai";

const sidebarBaseWidth = '12.5rem';
export const SidebarContainer = styled.div<{ $isOpen?: boolean; }>`
  flex: 0 0 ${sidebarBaseWidth};
	@media (min-width: ${breakpoint.md}) {
		flex: 0 0 11rem;
	}
	
	height: 100%;

	z-index: 1;
	overflow: hidden;

	background-color: var(--background-black-1);

	transition: width 0.5s;

	@media(max-width: ${breakpoint.md}) {
		position: absolute;
		left: 0px;
		bottom: 0px;
		top: 0px;

		width: ${props => props.$isOpen ? sidebarBaseWidth : '0px'};
		background-color: var(--background-black-3);
	}

	>div{
		// padding: ${props => props.$isOpen ? '2rem 1rem' : '2rem 0'};
		padding: 2rem 1rem;
		@media (min-width: ${breakpoint.md}) {
			padding-left: 0px;
		}
		overflow: hidden;
		white-space: nowrap;
	}

`;

export const SidebarTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 1.5rem;
	font-weight: 600;
	color: var(--gray-2);

	padding-bottom: 1rem;
`;

export const StyledCloseIcon = styled(AiOutlineClose)`
	cursor: pointer;
	
	@media(min-width: ${breakpoint.md}) {
		display: none;
	}
`;

export const SidebarNavLink = styled.div`
	display: flex;
	column-gap: 0.5rem;
	align-items: center;

	font-size: 1.1rem;
	font-weight: 500;
	color: var(--red-1);
	cursor: pointer;
`;

