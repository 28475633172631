import styled from "styled-components";

export const OptionsContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
	padding: 0.5rem;
	border-radius: 5px;
	background-color: var(--gray-4);
	width: 200px;
	cursor: pointer;
`;

export const Title = styled.div`
	font-size: 1rem;
	font-weight: 600;
	color: white;
`;

export const Email = styled.div`
	font-size: small;
	font-weight: 600;
	color: var(--gray-1);
`;

export const UsernameAvatar = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50px;
	background-color: var(--red-1);
	border-radius: 50px;
	width: 45px;
	height: 45px;

	font-size: 1.1rem;
	font-weight: 500;
`;