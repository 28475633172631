import { differenceInMinutes, format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EspCardContainer,
  EspNameContainer,
  EspNameInput,
  EspNameLabel,
} from "./EspCard.styles";
import { EspTag } from "./EspCard.styles";
import { BsCheckLg, BsPencilSquare } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { Flex } from "../../../components/Flex/Flex.styles";
import texts from "../../../constants/texts";
import sensorService from "../../../services/http/sensor.service";
import { useTheme } from "styled-components";
import Loading from "../../../components/Loading/Loading";

const ACTIVITY_TRESHOLD_MIN = 15;

interface IActiveCardProps {
  sensorId: string;
  macAddress: string;
  lastDataReceivedAt?: Date;
  espName: string;
}

export function ActiveCard(props: IActiveCardProps) {
  const { sensorId, macAddress, lastDataReceivedAt } = props;
  let initialEspName = props.espName;
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    pages: {
      home: {
        espCard: { status, tooltip },
      },
    },
  } = texts;

  const [minutesSinceLastTemperature, setMinutesSinceLastTemperature] =
    useState<number>(0);
  const [tooltipLabel, setTooltipLabel] = useState(tooltip.noData);

  const [isEditing, setIsEditing] = useState(false);
  const [espName, setEspName] = useState(initialEspName);
  const [cardLoading, setCardLoading] = useState(false);


  useEffect(() => {
    if (lastDataReceivedAt == null) {
      setTooltipLabel(tooltip.noData);
    } else {
      const label = tooltip.lastData.replace(
        "{0}",
        format(lastDataReceivedAt, "dd/MM/YYY 'às' HH:mm'h'")
      );

      setTooltipLabel(label);

      const currentDate = new Date();
      setMinutesSinceLastTemperature(
        differenceInMinutes(currentDate, lastDataReceivedAt)
      );
    }
  }, [lastDataReceivedAt]);

  function onCardClicked(macAddress: string) {
    if (isEditing) {
      return;
    }
    if (lastDataReceivedAt != null) {
      navigate(`/dashboard/${macAddress}`);
    }
  }
  function onEditClicked(event: React.MouseEvent<SVGElement>) {
    event.stopPropagation();
    setIsEditing(true);
    document.getElementById("esp-input")?.focus();
  }
  function onCancelClicked(event: React.MouseEvent<SVGElement>) {
    event.stopPropagation();
    setIsEditing(false);
    setEspName(initialEspName);
  }
  function onAcceptClicked(event: React.MouseEvent<SVGElement>) {
    event.stopPropagation();
    setIsEditing(false);
    setCardLoading(true);

    sensorService.updateName(sensorId, espName, macAddress)
      .then((request) => {
        initialEspName = request.data.name ?? '';
        //TODO: show OK toast
      },
        (error) => {
          //TODO: show error toast
          console.log(error);
          setEspName(initialEspName);
        })
      .finally(() => setCardLoading(false));
  }
  function onInputClicked(event: React.MouseEvent<HTMLInputElement>) {
    event.stopPropagation();
  }
  function onInputChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setEspName(event.currentTarget.value);
  }


  return (
    <EspCardContainer
      $isEditing={isEditing}
      onClick={() => onCardClicked(macAddress)}
      data-tooltip-id="my-tooltip"
      data-tooltip-content={tooltipLabel}
    >
      {EspBodyComponent()}
    </EspCardContainer>
  );

  function EspBodyComponent() {
    if (cardLoading) {
      return <Loading/>;
    }
    return (
      <>
        {EspNameComponent(isEditing, espName)}

        <div>{macAddress}</div>

        {EspActivityTag()}
      </>
    );
  }
  function EspNameComponent(isEditing: boolean, espName: string) {
    if (isEditing) {
      return (
        <EspNameContainer>
          <div style={{ flexGrow: 1 }}>
            <EspNameInput
              id="esp-input"
              onClick={onInputClicked}
              onChange={onInputChanged}
              value={espName}
              maxLength={50}
              autoFocus={true}
            ></EspNameInput>
          </div>
          <Flex $columnGap="1rem">
            <FaTimes
              cursor={"pointer"}
              onClick={onCancelClicked}
              color="var(--red-2)"
              fontSize="1.2rem"
            ></FaTimes>
            <BsCheckLg
              cursor={"pointer"}
              onClick={onAcceptClicked}
              color="var(--green-2)"
              fontSize="1.2rem"
            ></BsCheckLg>
          </Flex>
        </EspNameContainer>
      );
    }
    return (
      <EspNameContainer>
        <EspNameLabel>{espName}</EspNameLabel>
        <BsPencilSquare
          onClick={onEditClicked}
          style={{ flexShrink: 0 }}
        ></BsPencilSquare>
      </EspNameContainer>
    );
  }
  function EspActivityTag() {
    if (
      lastDataReceivedAt == null ||
      minutesSinceLastTemperature > ACTIVITY_TRESHOLD_MIN
    ) {
      return (
        <EspTag $isOnline={false}>
          <span>{status.offline}</span>
        </EspTag>
      );
    }

    return (
      <EspTag $isOnline={true}>
        <span>{status.online}</span>
      </EspTag>
    );
  }
}
