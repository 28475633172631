import styled from "styled-components";
import { breakpoint } from "../../styles/theme";

export const Flex = styled.div({
	display: 'flex',
	alignItems: 'center',
	padding: '1rem 0 1rem 2',
})

export const SidebarButton = styled.button`
	font-size: 24px;
	@media (min-width: ${breakpoint.md}){
		display: none;
	}
`;