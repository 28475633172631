import { format, startOfYesterday } from "date-fns";
import texts from "../../../constants/texts";
import { ChartTitleContainer } from "./ChartTitle.styles";

interface ChartTitleProps {
  title: string;
  isLoading: boolean;
}
export function ChartTitle(props: ChartTitleProps) {
  const {
    pages: {
      dashboard: {
        charts: { acceleration },
      },
    },
  } = texts;

  const defaultText = acceleration.title.replace(
    "{0}",
    "--/--/---- às --:--:--");


  return (
    <ChartTitleContainer>
      {props.isLoading ? defaultText : props.title}
    </ChartTitleContainer>
  );
}