import styled from "styled-components";
import { Flex } from "../../../components/Flex/Flex.styles";

export const FlexColumn = styled.div({
	display: 'flex',
	flexDirection: 'column',
	rowGap: '0.5rem',
	width: '100%'
})

export const FilterContainer = styled(Flex)`
justify-content: flex-start;
align-items: center;
column-gap: 0.5rem;
`;

export const Title = styled.span({
	fontWeight: '600',
	color: '#DBDCDF',
	fontSize: '1.125rem',
})

export const Row = styled.div({
	display: 'flex',
	alignItems: 'center',
	columnGap: '0.5rem',
})

export const SizedBox = styled.div({
	flexBasis: '210px',
	flexShrink: 1,
	
})
