import './date-picker.scss';
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useEffect, useState } from "react";
import { FaRegCalendarAlt, FaAngleDown } from "react-icons/fa";
import { DatePickerNavigationLeft, DatePickerNavigationRight, DatePickerTitle, LoadingLabel, Row } from './DatePicker.styles';
import { Flex } from '../Flex/Flex.styles';
import { format, isSameMonth, isSameYear, isThisMonth, startOfMonth, startOfYear } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR'
import Utils from '../../services/utils';
import texts from '../../constants/texts';

interface ICustomDatePickerProps {
  availableDates: Date[];
  selectedDate?: Date;
  onDateChange: (date: Date) => void;
  onMonthChange: (date: Date) => void;
  isLoadingMonthDates: boolean;
}
export default function CustomDatePicker(props: ICustomDatePickerProps) {
  const loadingLabel = texts.pages.dashboard.filter.datePicker.loadingLabel;
  const { selectedDate, onDateChange = () => { }, availableDates: availableDates = [], isLoadingMonthDates } = props;

  const [monthsLoaded, setMonthsLoaded] = useState<Date[]>([startOfMonth(new Date())]);
  const [calendarMonth, setCalendarMonth] = useState<Date>(startOfMonth(new Date()));

  useEffect(() => {
    if (selectedDate == null) {
      return;
    }
    if (!Utils.dateListHasMonth(monthsLoaded, selectedDate)) {
      setMonthsLoaded(monthsLoaded.concat(startOfMonth(selectedDate)));
    }
    if (!isSameMonth(selectedDate, calendarMonth) || !isSameYear(selectedDate, calendarMonth)){
      setCalendarMonth(startOfMonth(selectedDate))
    }
  }, [selectedDate]);

  const CustomInput = forwardRef<HTMLButtonElement, any>(({ value, onClick }, ref) => (
    <button className={`inline-date-picker ${Utils.listIsEmpty(availableDates) ? 'disabled' : ""}`} onClick={onClick} ref={ref}>
      <Row className="justify-content-between">
        <Row className="date-icon">
          <FaRegCalendarAlt color="var(--gray-1)" />
          <span>{value === '' ? 'Data' : value}</span>
        </Row>
        <FaAngleDown></FaAngleDown>
      </Row>
    </button>
  ));

  function onDateClicked(date: Date) {
    onDateChange(date);
    setCalendarMonth(startOfMonth(date))
  }
 
  function onMonthChange(newMonth: Date) {
    if (Utils.dateListHasMonth(monthsLoaded, newMonth)) {
      return;
    }
    setMonthsLoaded(monthsLoaded.concat(newMonth));

    setCalendarMonth(newMonth)

    props.onMonthChange(newMonth);
  }

  function DatePickerHeader(props: Pick<ReactDatePickerCustomHeaderProps, "date" | "decreaseMonth" | "increaseMonth">) {
    return (
      <Flex className='column'>
        <Flex className='justify-content-between'>
          <DatePickerNavigationLeft onClick={() => props.decreaseMonth()}>
            <span className='react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'></span>
          </DatePickerNavigationLeft>
          <DatePickerTitle>{Utils.capitalizeFirstLetter(format(props.date, "MMMM yyyy", { locale: ptBR }))}</DatePickerTitle>
          <DatePickerNavigationRight $isVisible={!isThisMonth(props.date)} onClick={() => props.increaseMonth()}>
            <span className='react-datepicker__navigation-icon react-datepicker__navigation-icon--next'></span>
          </DatePickerNavigationRight>
        </Flex>

        {isLoadingMonthDates ? <LoadingLabel>{loadingLabel}</LoadingLabel> : null}

      </Flex>

    );
  }

  return (
    <DatePicker disabled={false} includeDates={availableDates} onMonthChange={onMonthChange} selected={selectedDate} onChange={onDateClicked} openToDate={calendarMonth}
      renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => DatePickerHeader({ date, decreaseMonth, increaseMonth })}
      customInput={<CustomInput />} calendarClassName="custom-calendar-modal" dateFormat="dd/MM/yyyy" />
  );
}
