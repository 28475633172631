import styled from "styled-components";
import { BasePopupContainer } from "../Popover/ThemePopover.styles";

export const Row = styled.div({
	display: 'flex',
	alignItems: 'center',
	columnGap: '0.5rem',
})

export const TimePickerInline = styled.div<{ $isDisabled?: boolean }>`
	width: 100%;
	color: var(--gray-1);
	border-radius: 5px;
	border: 1px solid var(--gray-1);
	padding: 0.3rem 0.5rem;
	cursor: ${props => props.$isDisabled ? 'default' : 'pointer'};
`

export const TimePopupContent = styled(BasePopupContainer)`
	display: grid;
	grid-template-columns: repeat(auto-fit, 85px);
	gap: 0.5rem;

	max-width: 300px;
	max-height: 340px;
	overflow-y: auto;

  white-space: nowrap;
`

export const TimeContainer = styled(BasePopupContainer) <{ $selected: boolean; }>`
	padding: 8px 20px;
	cursor: pointer;
	text-align: center;

	background-color: ${props => props.$selected ?
		'var(--red-1)' :
		'var(--background-black-3)'};
	font-weight: ${props => props.$selected ?
		'600' :
		'400'};
`;
