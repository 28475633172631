import axios from "axios";
import { ListLimitsResponseDto } from "../responses/list-limit-response.dto";
import { CreateSensorLimitDTO } from "../dto/create-sensor-limit.dto";

class LimitService {
  private readonly baseUrl = process.env.REACT_APP_REST_API_ENDPOINT + '/limits';

  public listSensorLimits(sensorId: string) {
    return axios.get<ListLimitsResponseDto[]>(this.baseUrl + `/${sensorId}`);
  }

  public saveSensorLimit(dto: CreateSensorLimitDTO) {
    return axios.post<CreateSensorLimitDTO>(this.baseUrl, dto);
  }

}
const limitService = new LimitService();
export default limitService;