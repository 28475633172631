import styled from "styled-components";

export const ThemeInput = styled.input.attrs(props => ({
  ...props
})) <{ $width?: string, $hasError?: boolean  }>`
  width: ${props => props.$width ?? 'auto'};
  color: ${props => props.theme.input.color};
  background-color: ${props => props.theme.input.backgroundColor};
  font-size: ${props => props.theme.input.fontSize};
  border-radius: ${props => props.theme.input.borderRadius};
  padding:  ${props => props.theme.input.padding};

  border: 1px solid ${props => props.theme.input.borderColor};

  &:hover{
		border-color: ${props => props.theme.input.borderColorHovered};
	}

  &:focus{
		border-color: ${props => props.theme.input.borderColorFocused};
	}

   ${props => props.$hasError ? 
    `
      box-shadow: 0px 0px 0px 2px ${props.theme.input.borderColorError};
      &:hover, &:focus{
        border-color: ${props.theme.input.borderColorError};
        box-shadow: 0px 0px 0px 2px ${props.theme.input.borderColorError};
	    }
    ` 
    : ""}
`;

